import { useLayoutEffect } from "react";
import { useRef } from "react";

/**
 * DANGEROUS!!!!!!
 *
 * This is meant as a backwards-compatability tool
 * until we've re-written all legacy components as React.
 *
 * This component will render the given HTML and execute any script
 * tags that it finds as well. Use this CAREFULLY as it's basically
 * the equivalent of blindly eval'ing code.
 *
 * Taken with help from: https://macarthur.me/posts/script-tags-in-react/
 */
export default function ComponentFromHTML({ html }) {
  const ref = useRef();

  useLayoutEffect(() => {
    const range = document.createRange();
    range.selectNode(ref.current);

    const documentFragment = range.createContextualFragment(html);

    // These steps allow <script> tags to run, as setting
    // the innerHTML does not evaluate them.
    ref.current.innerHTML = "";
    ref.current.append(documentFragment);
  }, []);

  return <div ref={ref} dangerouslySetInnerHTML={{ __html: html }} />;
}
