import NameThisListCard from "./nameThisListCard";

const ShareListModalHeader = (props) => {
  const close_modal = function(){
    close_modal_control();
  }

  return (
    <div id="share_list_modal_header" className="modal_header black_modal_header">
      <div className="close_x_white modal_close_offset" onClick={close_modal}></div>
      <h3>Share List</h3>
    </div>
  );
}

const ShareListModalBody = (props) => {
  const close_modal = function(){
    close_modal_control();
  }

  let classNames = ["button-small", "button-primary"];
  if(props.checked === 0){
    classNames.push("button-disable");
  }

  /**
   * https://www.pivotaltracker.com/story/show/186622915
   * The keyword "ref" has a special meaning in React,
   * it should not be used.
   */
  const list = { ...props.list }
  list.list_ref = list.ref
  list.ref = null

  return (
    <div id="share_list_modal_body" className="modal_body">
      <form id="share_list_form" onSubmit={props.submit_form}>
        <input type='hidden' name='authenticity_token' value={props.authenticity_token} />
        <NameThisListCard {...list}/>
        <div className="share_list_instructions">Select the user(s) you wish to share this list with.  An email containing a link to this list will then be sent to them.</div>
        <PaginatedSelectableListOfUsers userList={props.users} toggleUser={props.toggleUser} featureFlags={props.feature_flags} uniqueId='share_list' mixpanelActions={['search colleagues on shared list modal']} columnsPerPage='2' usersPerColumn='5' legend='Share with' width="355px" size="small" />
        <div className="flag_event_buttons buttonGroup-small">
          <button type="reset" className="button-small button-secondary" onClick={close_modal}>Cancel</button>
          <button type="submit" className={classNames.join(" ")} id="share_list_submit_button" onClick={props.submit_form}>Share List</button>
        </div>
      </form>
    </div>
  );
}

var ShareListModal = createReactClass({
  getInitialState: function(){
    return {users:this.props.users,comment:"",checked:0}
  },
  submit_form: function(e){
    e.stopPropagation();
    e.preventDefault();
    var data = {
      comment:this.state.comment,
      users: _.map(_.filter(this.state.users, function(user){if(user){return user.checked}}), function(u){if(u){return u.id}})
    };
    if(data.users.length > 0){
      $("body").addClass("wait");
      $.ajax({
        url: "/lists/" + this.props.list._id + "/share_list",
        type: 'POST',
        headers: {
          'X-CSRF-Token': this.props.authenticity_token
        },
        data: data,
        success: function (result) {
          $("body").removeClass("wait");
          if (result.confirmation_display === null) {
            close_modal_control()
          }
          else {
            replace_modal_contents(result.confirmation_display)
            setTimeout(function(){
              close_modal_control();
            }, 5000);
          }
        }.bind(this)
      });
    }
    else{
      alert('Hey man... you have to choose at least someone to share this list with.')
    }
    return false;
  },
  toggleUser: function(user_id){
    var user = _.find(this.state.users, function(user){if(user && user.id){return user.id == user_id}});
    user.checked = !user.checked;
    var checked = this.state.checked;
    if(user.checked){
      checked++;
    }
    else{
      checked--;
    }
    this.setState({users: this.state.users,checked:checked})
  },
  updateComment: function(comment){
    this.setState({comment: comment});
  },
  render: function(){
    return (
      <div className="modal_wrapper">
        <ShareListModalHeader />
        <ShareListModalBody {...this.state} {...this.props} toggleUser={this.toggleUser} updateComment={this.updateComment}submit_form={this.submit_form}/>
      </div>
    );
  }
});

window.ShareListModal = ShareListModal;
export default ShareListModal;
