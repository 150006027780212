const ZoominfoPrivacyModalHeader = function(props) {
  const close_modal = () => {
    close_modal_control();
  }
  
  return (
    <div id="contact_search_privacy_modal_header" className="modal_header black_modal_header">
      <div className="close_x_white modal_close_offset" onClick={close_modal}></div>
      <h3>Please confirm usage</h3>
    </div>
  );
}

export default ZoominfoPrivacyModalHeader;
