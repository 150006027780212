import Reflux from "reflux";
import EventRepublishButton from "./eventRepublishButton";
import EventListTitle from "./eventListTitle";
import EventTable from "./eventTable";

const EventListPanel = createReactClass({
  mixins: [Reflux.connect(EventTableStore)],
  getInitialState: function () {
    return EventTableStore.eventData;
  },
  componentDidMount: function () {
    EventTableAction.initialLoad(this.props.events, this.props.viz, this.props.industry_type, this.props.query, this.props.init_repub_count, this.props.created_by);
  },
  render: function () {
    return (
      <div>
        <EventRepublishButton count={this.state.repub_count} />
        <EventListTitle viz={this.state.viz} />
        <EventTable {...this.state} />
      </div>
    );
  }
});

window.EventListPanel = EventListPanel;
export default EventListPanel;
