import createReactClass from "create-react-class";
import Reflux from "reflux";
import ListFeedActionStore from "../../stores/pivotLists/listFeedActionStore";
import ListFeedAction from "../../stores/actions/pivotLists/listFeedActions";

const MapViewButton = (props) => {
  const click_action = function(e){
    e.preventDefault();
    window.location.href = "/mapping?query=&category=all_event&time_select=time_any&org_list_id=" + props.list_id;
  }

  return (
    <button id="search_via_map" onClick={click_action} className="button-small button-primary" title="Display the activity for all the companies on this list in a map view">View on Map</button>
  );
}

var CreateFeedButton = (props) => {
  const click_action = function(e){
    e.preventDefault();
    ListFeedAction.createFeed();
  }

  var text = props.creating_feed ? "Creating feed" : "Save Feed";
  return (
    <button id="create_a_feed_from_a_list" onClick={click_action} className="button-small button-primary" title="Create a feed of the activity for all the companies on this list in a timeline view">{text}</button>
  );
}

var ViewFeedButton = (props) => {
  const click_action = function(e){
    e.preventDefault();
    window.location.assign("/feeds/" + props.feed_id + "/display_saved_feed");
  }

  return(
    <button id="see_a_feed_from_a_list" onClick={click_action} className="button-small button-primary" title="View the feed of activity for all the companies on this list">See Activity Feed</button>
  );
}

var ViewActivityButton = (props) => {
  let list_type = String(props.list_type);
  const click_action = function(e){
    e.preventDefault();

    let list_version = Zymewire.user.feature_tags.includes("list_of_other_entities") ? 'user' : 'org'
    var url = `/facet_search?${list_version}_list_id=${props.list_id}&category=all_event&time_select=time_any`;

    if(props.is_crm_list){
      url = "/facet_search?relationships%5B%5D=crm&category=all_event&time_select=time_any"
    }
    window.location.assign(url);
  }
  return(
    <button id="view_list_activity" onClick={click_action} className="button-primary button-small" title={`View the activity for all the ${list_type.toLowerCase()} on this list in a timeline view`}>Search {list_type} for Activity</button>
  );
}

var ListFeedPanel = createReactClass({
  mixins: [Reflux.connect(ListFeedActionStore)],
  getInitialState: function () {
    return ListFeedActionStore.data;
  },
  UNSAFE_componentWillMount: function(){
    ListFeedAction.initialLoad(this.props)
  },
  render: function(){
    const params = JSON.stringify(this.state) === "{}" ? this.props : this.state
    var create_feed_button;
    var link_to_activity;
    const map_view_button = <MapViewButton {...params} />
    if(this.props.linked_to_feed){
      link_to_activity = <ViewFeedButton {...params} />
    }
    else{
      create_feed_button = <CreateFeedButton {...params} />
      link_to_activity = <ViewActivityButton {...params} />
    }
    return (
      <div className="buttonGroup-small list_feed_panel_buttons">
        {map_view_button}
        <span>&nbsp;</span>
        {create_feed_button}
        <span>&nbsp;</span>
        {link_to_activity}
      </div>
    );
  }
});

window.ListFeedPanel = ListFeedPanel;
export default ListFeedPanel;
