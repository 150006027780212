import Reflux from "reflux";
import OrgEntity from "./orgEntity";

const EventOrgEntities = createReactClass({
  mixins: [Reflux.listenTo(EventTableStore)],
  getInitialState: function(){
    return EventTableStore.eventData;
  },
  render: function () {
    let  refsInEvent = [];
    const allIndustry = this.state.industry_type === "";
    const orgEntities = this.props.orgs.map(function (org) {
      refsInEvent = refsInEvent.concat(org.publishable_concept_refs);
      return <OrgEntity key={org.rec_id} org={org}/>;
    });
    if (!allIndustry && !refsInEvent.includes(this.state.industry_type)) {
      return (
        <div className="mismatched-industry-highlight">{orgEntities}</div>
      );
    }
    else {
      return (
        <div className="rspec-event-org-entities">{orgEntities}</div>
      );
    }
  }
});

export default EventOrgEntities;
