import PropTypes from "prop-types";

import Icon from "@javascript/reactComponents/lds/Icon";
import Heading from "@javascript/reactComponents/lds/typography/Heading";

const CopyToNewListSuccess = ({
  orgCount,
  listName,
  targetListId,
  targetListName,
}) => {

  const iconStyle = {
    alignSelf: "center",
  }

  return(
    <>
      <Icon
        name="special-success"
        style={iconStyle}
      />
      <Heading
        containerClassName="copy-to-new-list-success-header"
        size="h2"
      >
        New list created successfully!
      </Heading>
      <p>Successfully copied the {orgCount} companies on the list "{listName}" to "{targetListName}".</p>
      {/*remove this next element once the modal re-skin is complete: the modal will have this CTA in the footer as a button*/}
      <p><a id="see_new_list" href={`/lists/${targetListId}`}>See "{targetListName}"</a> list.</p>
    </>
  );
}

CopyToNewListSuccess.propTypes = {
  orgCount: PropTypes.string.isRequired,
  listName: PropTypes.string.isRequired,
  targetListId: PropTypes.string.isRequired,
  targetListName: PropTypes.string.isRequired,
};

window.CopyToNewListSuccess = CopyToNewListSuccess;
export default CopyToNewListSuccess;
