/**
 * React component that asks the user to confirm (or cancel) the deletion of selected feeds.
 * 
 * @author GJ <gordon@zymewire.com>
 * @namespace DeleteFeedsConfirmationModal
 *
 * @component
 * @example
 *
 * const feedsSelected = [1,2,3];
 * return (
 *   <DeleteFeedsConfirmationModal feedsSelected={feedsSelected} />
 * )
 *
 */
const DeleteFeedsConfirmationModal = function(props) {
  /**
   * Handle the confirm delete click event, submitting the feedsSelected via AJAX
   * upon success, redirect to /feeds
   */
  const deleteSelectedFeeds = () => {
    $("body").addClass("wait");

    const data = { feeds_selected: props.feedsSelected }

    $.ajax({
      url: '/feeds/delete_feeds',
      type: 'POST',
      data: data,
      success: function () {
        window.location.href = '/feeds';
      }
    });
  }

  /**
  * Handle the cancel delete click event, closing the modal
  */
  const cancelDelete = () => {
    close_modal_control();
  }

  let feeds = 'that feed';
  if(props.feedsSelected.length > 1){
    feeds = 'all ' + props.feedsSelected.length + ' feeds';
  }

  return (
    <div className="modal_wrapper">
      <div className="modal-header modal_header">
        <button className="close" onClick={cancelDelete} data-dismiss="modal">×</button>
        <h3 id="modal_flag_title">Are you sure?</h3>
      </div>
      <div className="modal-body modal_body">
        <div className="container-fluid" id="modal_content_holder">
          <div className="row-fluid">
            <div className="span12">
              <h3>Are you sure you wish to delete {feeds}? <span className="alert_text">This action cannot be undone and may take up to 5 minutes, depending on the size (and number) of the feeds.</span></h3>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button onClick={cancelDelete} data-dismiss="modal" className="button-small button-secondary">Cancel</button>
        <button onClick={deleteSelectedFeeds} id="confirm_saved_feed_delete_button" className="button-small button-critical TEMPORARY-MARGIN-LEFT-SMALL">Delete</button>
      </div>
    </div>
  );
}

window.DeleteFeedsConfirmationModal = DeleteFeedsConfirmationModal;
export default DeleteFeedsConfirmationModal;
