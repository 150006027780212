import BootstrapRow from "../../generic/htmlElements/bootstrapRow";
import BootstrapSpan from "../../generic/htmlElements/bootstrapSpan";

const ZoomInfoHeader = function(props) {
  var total_credits_this_month = props.remaining_for_month + props.spent_this_month;
  var badge;
  var isApolloActive = _.contains(Zymewire.user.feature_tags, "can_use_apollo_contact_search");

  if(!isApolloActive) {
    if (props.remaining_for_month > 0) {
      var title_text = "You have " + props.remaining_for_month + " out of " + total_credits_this_month + " contact reveal credits remaining for this month. You can spend up to " + props.allocation_per_month + " credits each calendar month.";
      badge = <div id="zinfo_credit_badge" className="pull-right" title={title_text}><h1><span className="z_info_credit_badge z_info_credit_badge_positive">{props.remaining_for_month}</span></h1></div>;
    }
    else {
      var title_text = "Sorry, you have used all your credits for this month. You can spend up to " + props.allocation_per_month + " credits each calendar month.  Contact " + I18n.t("contact.support_email") + " if you want to discuss changing your limits.";
      badge = <div id="zinfo_credit_badge" className="pull-right" title={title_text}><h1><span className="z_info_credit_badge z_info_credit_badge_empty">{props.remaining_for_month}</span></h1></div>;
    }
  }
  var img_style = { marginTop: '-3px' };
  const help_title_text = "Not sure how to use " + I18n.t("brand.name") + " to find new contacts? Click for an explanation.";
  const help_url = I18n.t("help.articles.finding_contact_info");
  return (
    <BootstrapRow fluid>
      <BootstrapSpan wide="9">
        <h1>Contact search <a href={help_url} className="help_title" title={help_title_text}>Find out more.</a></h1>
      </BootstrapSpan>
      <BootstrapSpan wide="3">
        {badge}
      </BootstrapSpan>
    </BootstrapRow>
  );
}

export default ZoomInfoHeader