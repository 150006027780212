import { useRef, useState } from "react";
import Dropdown, { DropdownTrigger } from "../lds/dropdown/Dropdown";
import DropdownList from "../lds/dropdown/DropdownList";
import Input from "../lds/Input";

const triggerStyles = {
  marginBottom: "10px",
};

export default function CopyToExistingListDropdown({ lists }) {
  const [open, setOpen] = useState(false);

  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState();

  const triggerRef = useRef();

  const handleItemSelected = (item) => {
    setSelected(item.label);
    setOpen(false);
  };

  const items = lists.map((list) => ({ label: list.name, value: list._id }));

  const filteredItems = items.filter((item) =>
    item.label.toLocaleLowerCase().includes(search.toLocaleLowerCase())
  );
  const hasItems = filteredItems.length > 0;

  const widthValue = triggerRef.current?.getBoundingClientRect().width
  const dropdownMaxWidth = widthValue ? widthValue + "px" : null;

  return (
    <Dropdown
      id="copy_to_existing_list_dropdown"
      size="small"
      ENABLE_LEGACY_DROPDOWN_SUPPORT
      LEGACY_DROPDOWN_ID="target_lists"
      maxWidth={dropdownMaxWidth} // Don't extend beyond parent modal
      maxHeight="300px"
      onItemSelected={handleItemSelected}
      onDropdownClose={() => setOpen(false)}
    >
      <DropdownTrigger>
        <div
          ref={triggerRef}
          style={triggerStyles}
          onClick={() => setOpen(true)}
        >
          <Input
            id="copy_to_existing_list_dropdown_trigger"
            readOnly
            value={selected || "(Name of a list)"}
            onValueChange={() => {}}
            size="small"
            width="100%"
            trailingIcon={"chevron-" + (open ? "up" : "down")}
          />
        </div>
      </DropdownTrigger>

      <Input
        width="100%"
        size="small"
        value={search}
        onValueChange={setSearch}
        leadingIcon="search"
        placeholder="Search"
        helperText={!hasItems ? "No search results" : ""}
        helperTextVariant={!hasItems ? "info" : "hidden"}
        helperTextStylesOverride={{
          marginBottom: hasItems ? "" : "var(--dropdown-list-space-paddingB)",
        }}
        forceShowHelperText
        showHelperTextIcon={false}
        showLabel={false}
      />
      <DropdownList items={filteredItems} />
    </Dropdown>
  );
}

window.CopyToExistingListDropdown = CopyToExistingListDropdown;
