import { useContext } from "react";
import PropTypes from "prop-types";

import Button from "../lds/Button";
import { FeedsIndexContext } from "../../stores/FeedsIndex";

const FeedsIndexDeleteButton = ({ group }) => {
  const { getCheckboxArray } = useContext(FeedsIndexContext);

  const groupCheckboxArray = getCheckboxArray(group);
  const buttonVariant = groupCheckboxArray.length > 0 ? "critical" : "disable";

  const onDeleteClick = () => {
    if (groupCheckboxArray.length > 0) {
      ReactDOM.render(React.createElement(DeleteFeedsConfirmationModal, {feedsSelected: _.uniq(groupCheckboxArray)}), document.getElementById("modal_content"));
      open_modal_control();
    }
  };

  return (
      <Button
        children="Delete"
        icon="delete"
        id={`delete_${group}`}
        onClick={onDeleteClick}
        size="small"
        variant={buttonVariant}
      />
  );
};

FeedsIndexDeleteButton.propTypes = {
  group: PropTypes.string.isRequired,
};

export default FeedsIndexDeleteButton;
