import SfFormFieldsCore from "./sfFormFieldsCore";
import SfFormFieldsOptional from "./sfFormFieldsOptional";
import SfFormErrorMessage from "./sfFormErrorMessage";
import SfFormWaitingMessage from "./sfFormWaitingMessage";

import _ from "underscore";

const SfAccountForm = createReactClass({
  getInitialState(){
    return {
      coreFields: null,
      created: false,
      errorBuildingForm: false,
      errors: false,
      optionalFields: null,
    };
  },
  retrieveSfAccountFields(){
    let url = "/company/" + this.props.org_id + "/retrieve_salesforce_account_fields";
    let self = this;

    $.ajax({
      url: url,
      type: "GET",
      headers: {
        // form_authenticity_token TODO: this is ignored, yet somehow the header is still set (deprecate this header and it still works)
        "X-CSRF-Token": "<%= form_authenticity_token.to_s %>"
      },
      success: function(results) {
        const validFields = crmHelpers.pruneInvalidFields(results.fields);
        const [coreFields, optionalFields] = _.partition(validFields, (field) => field.opt_custom !== true);
        self.setState({
          coreFields: coreFields,
          optionalFields: optionalFields,
        });
      },
      error: function(e) {
        console.error(e);
        self.setState({ errorBuildingForm: true });
      },
      statusCode: {
        401: () => { 
          location.reload()
        }
      }
    });
  },
  cancel(){
    SalesforceFormActions.close();
  },
  createAccount(e) {
    e.preventDefault();
    const data = $(".sf-account-form").serialize();
    const url = "/company/" + this.props.org_id + "/create_salesforce_account";
    let self = this;

    $.ajax({
      url: url,
      type: "POST",
      data: data,
      headers: {
        // form_authenticity_token TODO: this is ignored, yet somehow the header is still set (deprecate this header and it still works)
        "X-CSRF-Token": "<%= form_authenticity_token.to_s %>"
      },
      success: function(results) {
        if (results.response.status === 400) {
          self.setState({ errors: {error_fields: results.error_fields, error_messages: results.error_messages, error_codes: results.error_codes} });
        } else {
          self.setState({ errors: false, created: true });
          SFPanelWrapperActions.updateAccounts(results.accounts);
        }
      }
    });
  },

  // Renders the form entities
  // In the initial state it checks if state.coreFields has value:
  // IF IT DOES => Render the form with the fields
  // IF NOT     => Then call retrieve() fields to get the required fields from the Restforce API
  //               and display a message to the user informing them to wait while the form is
  //               being generated
  render() {
    const formStyle = {
      width: "100%",
      margin: "0 auto"
    };

    const buttonPad = {
      marginRight: "10px"
    };

    if (this.state.coreFields) {
      if (this.state.created) {
        return (
          <div>
            <div className="modal-body">
              <div style={formStyle}>
                <p>Successfully created a new Account in your Salesforce</p>
              </div>
            </div>
            <div id="new_feed_footer" className="modal-footer">
              <button type="reset" className="button-small button-primary" data-dismiss="modal">Close</button>
            </div>

          </div>
        );
      }
      else {
        return (
          <div>
            <form className="sf-account-form">
              <div className="modal-body">
                <div style={formStyle}>
                  <SfFormFieldsCore
                    errors={this.state.errors}
                    fields={this.state.coreFields}
                  />
                  <SfFormFieldsOptional
                    errors={this.state.errors}
                    fields={this.state.optionalFields}
                  />
                </div>
              </div>
              <div id="new_feed_footer" className="modal-footer container-fluid" style={{textAlign: "unset"}}>
                <div className="row-fluid">
                  <SfFormErrorMessage errors={ this.state.errors } className="span7"/>
                  <div className="span5">
                    <button type="reset" className="button-small button-secondary" data-dismiss="modal">Cancel</button>
                    <button type="submit" style={buttonPad} className="button-small button-primary button_spaced_10_left" onClick={this.createAccount}>Add Account</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        );
      }
    }
    else {
      if (this.state.errorBuildingForm) {
        return (
          <div><p>There was an issue building the form. Please try again later.</p></div>
        )
      } else {
        this.retrieveSfAccountFields();
        return <SfFormWaitingMessage />
      }
    }
  }
});

export default SfAccountForm;
