import React, { useRef } from "react";
import PropTypes from "prop-types";

import Icon from "@javascript/reactComponents/lds/Icon";
import { isString } from "lodash";

function HelperText({
  value = "",
  variant = "none",
  showHelperTextIcon = true,
  helperTextStylesOverride = {},
}) {
  const iconStyles = {
    visibility: showHelperTextIcon ? "visible" : "hidden",
  };

  return (
    <span
      className={"input-helperText-" + variant}
      style={helperTextStylesOverride}
    >
      <span className={"icon-" + variant + "-filled"} style={iconStyles} role="img" />
      <span>{value}</span>
    </span>
  );
}

const Input = ({
  className = "",
  disabled = false,
  focused = false,

  forceShowHelperText = false,
  height,

  helperText = "",
  helperTextStylesOverride = {},
  helperTextVariant = "none",
  id = null,

  label,
  labelPosition = "inside",
  leadingIcon,

  maxWidth,
  minWidth,
  multiLine = false,
  name = "",
  onValueChange,
  onSubmit,
  placeholder = " ",
  readOnly = false,

  showClearIcon = true,
  showHelperTextIcon = true,

  showLabel = true,
  size,
  trailingIcon,
  trailingIconOnClick = () => {},

  value,
  variant = "normal",
  width = 219,
}) => {
  const inputRef = useRef();

  const clearInput = (event) => {
    event.preventDefault();
    event.stopPropagation();

    inputRef.current?.focus();
    onValueChange("");
  };

  const jsxInput = !isString(value) && React.isValidElement(value)
  const regularInput = !jsxInput && !multiLine;
  const multiLineInput = !jsxInput && multiLine;

  const parentClass = "input-container-" + size;

  const innerContainerClasses = [
    `input-inner-container-${size}`,
    `input-inner-container-${variant}`,
  ];

  const inputClasses = [`lds-input-${size}`, `input-${variant}`];
  const showLabelInside = showLabel && labelPosition === "inside" && (multiLineInput || size === "large");

  if (className) {
    inputClasses.push(className);
  }

  // Inner container
  if (multiLine) {
    innerContainerClasses.push("input-inner-container-multiLine-" + size);
    inputClasses.push("input-multiLine-" + size)
  }
  if (leadingIcon) {
    innerContainerClasses.push("has-leading-icon");
  }
  if (disabled) {
    inputClasses.push("input-disable");
    innerContainerClasses.push("input-inner-container-disable");
  }
  if (focused) {
    innerContainerClasses.push("focused");
  }

  // HTML Input
  if (showLabelInside) {
    inputClasses.push("input-has-label");
  }

  const commonInputProps = {
    id,
    onChange: (event) => onValueChange(event.target.value),
    onKeyUp: (event) => { if (onSubmit && event.key === "Enter") {onSubmit()} },
    placeholder,
    value,
  };

  if (name) {
    commonInputProps.name = name;
  }

  const renderInput = () => {
    if (regularInput) {
      return (
        <input
          readOnly={readOnly || disabled}
          ref={inputRef}
          {...commonInputProps}
          className={inputClasses.join(" ")}
        />
      );
    }

    if (multiLineInput) {
      return (
        <textarea
          readOnly={readOnly || disabled}
          {...commonInputProps}
          className={`${inputClasses.join(" ")}`}
          style={{ height }}
        />
      );
    }

    if (jsxInput) return value

    return null;
  }

  const showOuterLabelTop = showLabel && labelPosition === "top";
  const outerLabelClassNames = ["lds-input-outer-label-" + size];

  if (showOuterLabelTop) {
    outerLabelClassNames.push("lds-input-outer-label-top");
  }

  const outerLabel = (
    <span className={outerLabelClassNames.join(" ")}>{label}</span>
  );

  return (
    <div className={parentClass} style={{ width, minWidth, maxWidth }}>
      {showOuterLabelTop && outerLabel}

      <div className={innerContainerClasses.join(" ")}>
        {leadingIcon && <Icon name={leadingIcon} className="icon-leading" />}

        {renderInput()}

        {showLabelInside && <label>{label || placeholder}</label>}
        {showClearIcon && !multiLineInput && !readOnly && (
          <span
            className="icon-clear-filled clear-input-trigger"
            onClick={clearInput}
          />
        )}

        {trailingIcon && (
          <Icon
            name={trailingIcon}
            className="icon-trailing"
            onClick={trailingIconOnClick}
          />
        )}
      </div>

      {(size !== "small" || forceShowHelperText) && (
        <HelperText
          value={helperText}
          variant={helperTextVariant}
          showHelperTextIcon={showHelperTextIcon}
          helperTextStylesOverride={helperTextStylesOverride}
        />
      )}
    </div>
  );
}

Input.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  focused: PropTypes.bool,

  forceShowHelperText: PropTypes.bool,
  height: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  helperText: PropTypes.string,

  helperTextStylesOverride: PropTypes.object,
  helperTextVariant: PropTypes.oneOf([
    "critical",
    "hidden",
    "info",
    "none",
    "success",
    "warning",
  ]),
  id: PropTypes.string,

  label: PropTypes.string,
  labelPosition: PropTypes.oneOf(["inside", "top"]),
  leadingIcon: PropTypes.string,

  maxWidth: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  minWidth: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),

  multiLine: PropTypes.bool,
  name: PropTypes.string,
  onValueChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,

  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  showClearIcon: PropTypes.bool,

  showHelperTextIcon: PropTypes.bool,
  showLabel: PropTypes.bool,
  size: PropTypes.oneOf([
    "small",
    "medium",
    "large",
  ]).isRequired,

  trailingIcon: PropTypes.string,
  trailingIconOnClick: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.string.isRequired,
  ]),

  variant: PropTypes.oneOf([
    "critical",
    "normal",
  ]),
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),

};

export default Input;
