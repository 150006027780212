import GenericModal from "../generic/modals/genericModal";
import GenericModalActions from "../generic/modals/genericModalActions";
import GenericModalBody from "../generic/modals/genericModalBody";
import GenericModalHeader from "../generic/modals/genericModalHeader";

const SearchErrorModal = createReactClass({
  mixins: [
    Reflux.connectFilter(FacetSearchStore, 'message', function(data) { return data.searchTerm.queryErrorMessage })
  ],
  onCancel() {
    FacetSearchStoreActions.clearQueryError();
  },
  render() { let allowModal = !_.isEmpty(this.state.message);
    const btnStyle = {
      margin: '0 3px'
    }
    return (
      <GenericModal open={allowModal} style={{ minWidth: '400px' }}>
        <GenericModalHeader>
          Search Error
          <div style={{flexGrow: 1}}></div>
        </GenericModalHeader>

        <GenericModalBody>
          { this.state.message + " "} See the <a href={I18n.t("help.site")}>help page</a> for advanced search.
        </GenericModalBody>

        <GenericModalActions>
          <div style={{ flexGrow: 1 }} />

          <button
            id="dismiss_search_error"
            className="button-small button-primary"
            style={btnStyle}
            onClick={this.onCancel}
          >Ok</button>
        </GenericModalActions>
      </GenericModal>
    )
  }
})

window.SearchErrorModal = SearchErrorModal;
export default SearchErrorModal;
