import Icon from "@javascript/reactComponents/lds/Icon";
import CHECKMARK_SVG from "../../../../../images/icons/checkmark-filled.svg";

const ListCreateSuccess = ({ listName }) => {
  return (
    <div className="status">
      <div
        className="delete-checkmark-background"
        style={{ marginBottom: "16px" }}
      >
        <Icon
          name="checkmark"
          style={{ height: 50, width: 50 }}
          className="icon-background"
        />
      </div>

      <p
        style={{
          textAlign: "center",
          fontSize: "20px",
          fontWeight: "700",
          padding: "16px 0",
        }}
        className={"type-body-bold-medium"}
      >
        List Generated Successfully!
      </p>
      <p style={{ fontSize: "16px" }}>{listName}</p>
    </div>
  );
};

export default ListCreateSuccess;
