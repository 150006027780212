import ZoominfoPrivacyModalBody from "./zoominfoPrivacyModalBody";
import ZoominfoPrivacyModalHeader from "./zoominfoPrivacyModalHeader";

const ZoominfoPrivacy = function(props) {
  return (
    <div className="modal_wrapper">
      <ZoominfoPrivacyModalHeader />
      <ZoominfoPrivacyModalBody location={props.location} privacy_confirmation={props.privacy_confirmation} />
    </div>
  );
}

export default ZoominfoPrivacy;
