import Reflux from "reflux";
import FacetSearchStore from "../../stores/facetSearchStore";
import { FacetSearchStoreActions } from "../../stores/actions/facetSearch/storeActions";

/**
 * Component responsible for rendering the breadbox for when one or more facets are selected (e.g. Condition, Therapy).
 *
 * It is able to render both locked/unlocked params, such that when it is viewed in a feed,
 * the locked params are not able to be removed.
 *
 * @param {object} props
 * @param {object} props.list - The selected criterias.
 */
const FacetSearchCriteriaEntities = createReactClass({
  mixins: [
    Reflux.connectFilter(FacetSearchStore, function (data) {
      return {
        filterCriteria: data.filterCriteria,
        facets: data.facets,
      };
    }),
  ],
  getInitialState() {
    return {
      filterCriteria: FacetSearchStore.data.filterCriteria,
      facets: FacetSearchStore.data.facets,
    };
  },
  render() {
    const { filterCriteria, facets } = this.state;

    const getFacet = (type, term) => {
      const terms = type !== "_id" ? facets[type].terms : [];
      let facet = _.find(terms, function (facet) {
        return facet.term === term;
      });
      if (typeof facet === "undefined") {
        facet = { term: term, name: term, count: 0 };
      }
      return facet;
    };

    const updateLockedTerms = (criterias) => {
      let original = Zymewire.original_feed_query;
      if (original && original.facets) {
        _.each(criterias, function (criteria) {
          if (original.facets[criteria.type]) {
            _.each(criteria.terms, function (facet) {
              if (_.contains(original.facets[criteria.type], facet.term)) {
                facet["locked"] = true;
              }
            });
          }
        });
      }
    };

    const criterias = [];
    const keys = _.keys(filterCriteria);

    _.forEach(keys, function (key) {
      let facets = {};
      facets.terms = _.map(filterCriteria[key], function (term) {
        return getFacet(key, term);
      });
      facets.type = key;
      criterias.push(facets);
    });

    if (criterias && criterias.length === 0) return null;

    updateLockedTerms(criterias);

    const createComponent = ({ criteria, term, index }) => {
      const term_name = decodeHTMLContent(term.name)
      const title =
        criteria.type !== "_id"
          ? `You are filtering your search by '${term_name}'. Click to remove this filter.`
          : "You restricted to a specific event. Click to remove this filter.";

      const terminology =
        criteria.type !== "_id" ? term_name : "A specific event";

      const terms = filterCriteria[criteria.type];

      return term.locked ? (
        <EntityCriteriaEntry
          key={index}
          term={term}
          title={title}
          terminology={terminology}
        />
      ) : (
        <EntityCriteriaRemove
          key={index}
          terms={terms}
          term={term}
          title={title}
          terminology={terminology}
          type={criteria.type}
        />
      );
    };

    let components = [];

    criterias.forEach((criteria) => {
      criteria.terms.map((term) => {
        const component = createComponent({
          criteria: criteria,
          term: term,
          index: `${criteria.type}_${term.name}`,
        });
        components.push(component);
      });
    });

    return <div>{components}</div>;
  },
});

/**
 * @param props {{ term: string, title: string, terminology: string }}
 */
const EntityCriteriaEntry = ({ term, title, terminology }) => {
  return (
    <div className="filter_item filter_item_entity" title={title}>
      <div
        className="filter_item_close"
        style={{ display: "inline-block" }}
      ></div>
      <div style={{ display: "inline-block" }}>
        <p
          style={{ marginLeft: "-5px", fontSize: "14px", color: "#000" }}
          onMouseOver={() => highlightMapMarker(term.name)}
          onMouseOut={() => removeHighlightMarker(term.name)}
        >
          <b>
            {terminology}
            <CrmConnectionImage term={term} />
          </b>
        </p>
      </div>
    </div>
  );
};

/**
 * @param props {{ terms: object, term: string, title: string, terminology: string, type: string }}
 */
const EntityCriteriaRemove = ({ terms, term, title, terminology, type }) => {
  const removeCriteria = (e) => {
    e.preventDefault();
    e.stopPropagation();
    facetRemove(type, terms, term.name);
  };

  const facetRemove = (type, terms, term) => {
    let changed = false;
    const encoded = escapeHtml(term);
    if (_.include(terms, term) || _.include(terms, encoded)) {
      var cleanedList = _.filter(
        terms,
        function (crit) {
          return !(crit === this || crit === escapeHtml(this));
        },
        term
      );

      if (cleanedList.length > 0) {
        FacetSearchStoreActions.updateFilterCriteria(type, cleanedList);
      } else {
        FacetSearchStoreActions.removeFilterCriteria(type);
      }
      changed = true;
    }
    if (changed) {
      doEventSearch();
    }
  };

  return (
    <a onClick={removeCriteria}>
      <div className="filter_item filter_item_entity" title={title}>
        <div
          className="filter_item_close"
          style={{ display: "inline-block", cursor: "pointer" }}
        >
          <i className="icon-remove"></i>
        </div>
        <div style={{ display: "inline-block", cursor: "pointer" }}>
          <p
            style={{ marginLeft: "-5px", fontSize: "14px" }}
            onMouseOver={() => highlightMapMarker(term.name)}
            onMouseOut={() => removeHighlightMarker(term.name)}
          >
            <b>
              {terminology}
              <CrmConnectionImage term={term} />
            </b>
          </p>
        </div>
      </div>
    </a>
  );
};

/**
 * @param props {{ term: string }}
 */
const CrmConnectionImage = ({ term }) => {
  const imageFor = (term) => {
    if (term.connected || term.in_crm) {
      switch (term) {
        case term.connected && term.in_crm:
          return (
            <span>
              <img
                src="/images/LinkedIn_Logo16px_grey.png"
                style={{
                  marginTop: "-2px",
                  marginLeft: "1px",
                  marginRight: "1px",
                }}
              />
              <img
                src="/images/sf_icon_grey.png"
                style={{
                  height: "16px",
                  width: "16px",
                }}
                className="ent_property_icon"
              />
            </span>
          );
        case term.connected:
          return (
            <span>
              <img
                src="/images/LinkedIn_Logo16px_grey.png"
                style={{
                  marginTop: "-2px",
                  marginLeft: "1px",
                  marginRight: "1px",
                }}
                title="You have a LinkedIn connection at <%= term.name %>."
              />
            </span>
          );
        default:
          return (
            <span>
              <img
                src="/images/sf_icon_grey.png"
                style={{
                  height: "16px",
                  width: "16px",
                }}
                className="ent_property_icon"
              />
            </span>
          );
      }
    }
  };

  return <span>{imageFor(term)}</span>;
};

window.FacetSearchCriteriaEntities = FacetSearchCriteriaEntities;
export default FacetSearchCriteriaEntities;
