import { useState } from "react";
import TabGroup from "../lds/tabs/TabGroup";
import Tab from "../lds/tabs/Tab";

export default function FeedsTabs({ myFeeds }) {
  const tabMapping = {
    personal: "#tab1",
    colleague: "#tab3",
  };

  const reverseTabMapping = Object.keys(tabMapping).reduce((acc, curr) => {
    acc[tabMapping[curr]] = curr;
    return acc;
  }, {});

  // User can refresh page when viewing colleagues' feeds
  const [selected, setSelected] = useState(
    reverseTabMapping[location.hash] || "personal"
  );

  const handleChange = (tab) => {
    setSelected(tab);
    location.assign(tabMapping[tab]);

    // Simulate existing behaviour
    setTimeout(() => window.scrollTo(0, 0), 0);
  };

  return (
    <>
      <TabGroup selectedTab={selected} onTabSelected={handleChange}>
        <Tab
          id="my_feeds_tab"
          label="My Feeds"
          value="personal"
          leadingIcon="feeds"
        />
        <Tab
          id="colleagues_feeds_tab"
          label="Colleagues’ Feeds"
          value="colleague"
          leadingIcon="team"
        />
      </TabGroup>
    </>
  );
}

window.FeedsTabs = FeedsTabs;
