import createReactClass from "create-react-class";

import FlagEventModalBody from "@javascript/reactComponents/flagged_events/flagEventModalBody";
import LegacyModalService from "@javascript/globals/LegacyModalService";
import { markFlagEventButtonAsFlagged } from "@javascript/reactComponents/utilities/flagEventUtilities";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@javascript/reactComponents/lds/modal/Modal";

////////////////////////////////////// new flag it -- kill old flag it once the can_use_flagged_event_reminder feature tag is default for all users
const FlagEventModal = createReactClass({
  getInitialState: function () {
    return { userList: this.props.users, comment: "", ruby_date: this.props.flagged_event_reminder };
  },
  UNSAFE_componentWillMount: function () {
    if (this.props.flag_for === 'others') {
      this.toggleUser(this.props.current_user);
    }
  },
  submit_form: function (e) {
    e.stopPropagation();
    e.preventDefault();
    $("body").addClass("wait");
    var url = "/flagged_events/" + this.props.event.id + "/flag_for";
    if (this.props.flag_for === 'others') {
      url = "/flagged_events/" + this.props.flagged_event_id + "/flag_for_others";
    }
    var data = {
      comment: this.state.comment,
      flagged_users: _.map(_.filter(this.state.userList, function (user) { return user.checked; }), function (u) { return u.id; })
    };
    if (this.state.ruby_date) {
      data.flagged_event_reminder = this.state.ruby_date;
    }
    $.ajax({
      url: url,
      type: 'POST',
      headers: {
        'X-CSRF-Token': this.props.authenticity_token
      },
      data: data,
      success: function (result) {
        if (this.props.flag_for === 'others') {
          if (result.success === true) {
            display_comment_panel(this.props.flagged_event_id, result.confirmation_display);
            update_status_panel(this.props.flagged_event_id, 'update');
            LegacyModalService.closeModal();
          }
          else {
            $("body").removeClass("wait");
            LegacyModalService.closeModal();
          }
        }
        else {
          $("body").removeClass("wait");
          if (result.confirmation_display === null) {
            LegacyModalService.closeModal();
          }
          else {
            LegacyModalService.openModal(LDSFlagEventModalSuccess, { notifiedUsers: result.notified_users || [] })
            if (result.flagged_for_user) {
              const selector = '#flagged_' + this.props.event.id;
              markFlagEventButtonAsFlagged(selector);

              if (typeof events !== 'undefined') {
                var event = _.find(events, function (event) {
                  return event.id == id;
                });
                event.flagged = true;
              }
            }
          }
        }
      }.bind(this)
    });
    return false;
  },
  toggleUser: function (user_id) {
    var user = _.find(this.state.userList, function (user) { return user.id == user_id; });
    if (user.currently_flagged == false) {
      user.checked = !user.checked;
      this.setState({ userList: this.state.userList });
    }
  },
  updateComment: function (comment) {
    this.setState({ comment: comment });
  },
  updateDate: function (date) {
    if (date instanceof Array) {
      if (date.length < 1) {
        this.setState({ ruby_date: null });
      }
      else {
        this.setState({ ruby_date: moment.tz(date[0], "America/New_York").utc().format() });
      }
    }
    else if (date) {
      this.setState({ ruby_date: moment.tz(date, "America/New_York").utc().format() });
    }
    else {
      this.setState({ ruby_date: null });
    }
  },
  render: function () {
    let button_text = 'Flag Event';

    if (this.props.flagged_event_reminder || (this.state.userList[0].name === 'You' && this.state.userList[0].currently_flagged)) {
      button_text = 'Update Flagged Event';
    }

    return (
      <Modal
        id="flag_event_modal"
        open={true}
        size="large"
        onClose={LegacyModalService.closeModal}
      >
        <ModalHeader>Flag an event</ModalHeader>

        <ModalBody>
          <FlagEventModalBody
            {...this.state}
            {...this.props}
            toggleUser={this.toggleUser}
            updateComment={this.updateComment}
            updateDate={this.updateDate}
            submit_form={this.submit_form}
          />
        </ModalBody>

        <ModalFooter align="flex-end">
          <div className="buttonGroup-medium">
            <button
              id="cancel_flag_event_button"
              className="button-medium button-secondary"
              onClick={LegacyModalService.closeModal}
            >
              Cancel
            </button>
            <button
              id="flag_event_button"
              onClick={this.submit_form}
              className="button-medium button-primary"
            >
              {button_text}
            </button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
});

window.FlagEventModal = FlagEventModal;
export default FlagEventModal;
