import LegacyModalService from "@javascript/globals/LegacyModalService";
import {
  Modal,
  ModalHeader,
} from "@javascript/reactComponents/lds/modal/Modal";
import ComponentFromHTML from "../../shared/ComponentFromHTML";

export default function GenericLegacyModal({ title, legacyModalContent }) {
  return (
    <Modal open={true} onClose={LegacyModalService.closeModal}>
      {/*
        Title markup is somewhat more complex than body or footer
        so it's better if we don't have to write it every time
      */}
      {title && <ModalHeader title={title} />}

      <ComponentFromHTML html={legacyModalContent} />
    </Modal>
  );
}

window.GenericLegacyModal = GenericLegacyModal;
