const ZoominfoPrivacyModalBody = function(props) {
  const close_modal = () => {
    close_modal_control();
  }

  var privacy_the = ['Czech Republic', 'Netherlands', 'United Kingdom'];
  var location = 'from an unlisted location';
  if (props.location) {
    if (privacy_the.includes(props.location)) {
      location = 'from the ' + props.location;
    }
    else {
      location = 'from ' + props.location;
    }
  }
  return (
    <div id="contact_search_privacy_modal_body" className="modal_body">
      <p className="contact_search_privacy_paragraph contact_search_privacy_explanation">This person appears to be {location}.   As data processors, neither our 3rd party contact providers, nor {I18n.t("brand.name")} has explicit consent from this person for you to email them.  To help you stay compliant with GDPR, please confirm that you are reaching out for business-to-business purposes between your business and this person’s employer for one of the following reasons:</p>
      <div className="contact_search_privacy_indent">
        <p className="contact_search_privacy_paragraph"><b>1)</b> - Any reason, because I have confirmed this person is not in fact in the  European Union (GDPR does not apply);</p>
        <p className="contact_search_privacy_paragraph"><b>OR 2a)</b> usage is based on the person having given consent to the use of her or his personal data for one or more specific purposes;</p>
        <p className="contact_search_privacy_paragraph"><b>OR 2b)</b> usage is necessary for the performance of a contract to which this person is party or in order to take steps at the request of this person prior to entering into a contract;</p>
        <p className="contact_search_privacy_paragraph"><b>OR 2c)</b> processing is necessary for compliance with a legal obligation to which my company  is subject;</p>
        <p className="contact_search_privacy_paragraph"><b>OR 2d)</b> processing is necessary in order to protect the vital interests of this person or of another natural person;</p>
        <p className="contact_search_privacy_paragraph"><b>OR 2e)</b> processing is necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in my company;</p>
        <p className="contact_search_privacy_paragraph"><b>OR 2f)</b> processing is necessary for the purposes of the legitimate interests pursued by my company or by a third party, except where such interests are overridden by the interests or fundamental rights and freedoms of this person which require protection of personal data, in particular where the data subject is a child.
        </p>
      </div>
      <div className="modal-footer">
        <button id="cancel_privacy_button" className="button-small button-secondary rspec_cancel_gdpr_modal" onClick={close_modal}>Cancel</button>
        <button id="confirm_privacy_button" type="submit" className="button-small button-primary button_spaced_10_left" onClick={props.privacy_confirmation}>Confirm</button>
      </div>
    </div>
  );
}

export default ZoominfoPrivacyModalBody;
