import Reflux from "reflux"
import { FacetSearchAliasSearchActions } from "./aliasSearchActions";

/**
 * Reflux action object
 *
 * @namespace FacetSearchStoreActions
 * @param [Array] of action names for use with FacetSearchStoreMutations
 */
const FacetSearchStoreActions = Reflux.createActions({

  // TODO alphabetize
  clinicalTrialStagesUpdate: { sync: true },
  clinicalTrialStatusesUpdate: { sync: true },

  updateFacetSearchResults: { sync: true },

  setFilterCriteria: { sync: true },
  updateFilterCriteria: { sync: true },
  addToFilterCriteria: { sync: true },
  removeFilterCriteria: { sync: true },

  setQueryTerm: { sync: true },
  clearQueryError: { sync: true },

  autocomplete: { sync: true },
  autocompleteAliasSearch: { sync: true },
  autocompleteEntityValue: { sync: true },
  autocompleteEntity: { sync: true },
  autocompleteLocation: { sync: true },
  autocompleteRecentSearch: { sync: true },
  autocompleteSpecialCases: { sync: true },
  autocompleteText: { sync: true },
  autocompleteUserHome: { sync: true },
});

/**
 * Reflux store object
 *
 * @namespace FacetSearchStoreMutations
 *
 * Store listens to FacetSearchStoreActions events and updates its data object.
 * Is used from outside the component to set up data in different points of the lifecycle.
 */
const FacetSearchStoreMutations = {
    // TODO alphabetize
  onClinicalTrialStagesUpdate: function(updateData, options = {doSearch: false, triggerStateUpdate: false} ) {
    const { doSearch, triggerStateUpdate } = options;
    const { clinicalTrialStages } = this.data

    const updatedState = {...clinicalTrialStages, ...updateData};

    const trigger = () => {
      if (triggerStateUpdate) this.trigger(this.data)
    }

    this.data.clinicalTrialStages = updatedState;

    if(doSearch) {
      doEventSearch().then(trigger);
    } else {
      trigger()
    }
  },
  onClinicalTrialStatusesUpdate: function(updateData, options = {doSearch: false, triggerStateUpdate: false} ) {
    const { doSearch, triggerStateUpdate } = options;
    const { clinicalTrialStatuses } = this.data

    const updatedState = {...clinicalTrialStatuses, ...updateData};

    this.data.clinicalTrialStatuses = updatedState;

    const trigger = () => {
      if (triggerStateUpdate) {
        this.trigger(this.data);
      }
    }


    if (doSearch) {
      doEventSearch().then(trigger);

    } else {
      trigger()
    }
  },
  onUpdateFacetSearchResults: function(results) {
    // TODO: Deep clone? Need to check if any nested attributes are mutated directly.
    this.data.facets = results.facets;
    this.data.total_count = results.count

    this.trigger(this.data)
  },
  onSetFilterCriteria(newCriteria) {
    this.data.filterCriteria = newCriteria
  },
  onUpdateFilterCriteria(type, value) {
    this.data.filterCriteria[type] = this.data.filterCriteria[type] || []

    this.data.filterCriteria[type] = value
  },
  onAddToFilterCriteria(type, value) {
    this.data.filterCriteria[type].push(value)
  },
  onRemoveFilterCriteria(type) {
    delete this.data.filterCriteria[type]
  },

  onSetQueryTerm(newTerm) {
    let res = validateQueryTerm(newTerm||"");
    if (res.valid) {
      this.data.searchTerm.queryTerm = newTerm;
      this.data.searchTerm.queryErrorMessage = null;
    } else {
      this.data.searchTerm.queryErrorMessage = res.errorMsg;
      this.trigger(this.data)
    }
  },

  onClearQueryError() {
    this.data.searchTerm.queryErrorMessage = null;
    this.trigger(this.data)
  },

  onAutocomplete: function(suggestion) {
    Zymewire.external_track_action("Autocomplete selection", {
      entity_type: suggestion.type,
      text_chosen: suggestion.display,
      matched: suggestion.matched,
    });
    const actionMap = {
      alias_search: "autocompleteAliasSearch",
      default: "autocompleteText",
      entity: "autocompleteText",
      location: "autocompleteLocation",
      entity_value: "autocompleteEntityValue",
      recent_search: "autocompleteRecentSearch",
      special_cases: "autocompleteSpecialCases",
      text: "autocompleteText"
    }
    const action = actionMap[suggestion.type] || actionMap.default;
    FacetSearchStoreActions[action](suggestion);

    // Sync visible input with typeahead
    $("#query_text_box").val($(".typeahead").typeahead('val'))
  },
  onAutocompleteAliasSearch: function(suggestion) {
    clearAutocompleteSuggestions();
    compoundLeftovers(suggestion.display, suggestion.official_name);
    FacetSearchAliasSearchActions.addAliasSearchRefs({ref: suggestion.ref, display: suggestion.official_name});
  },
  onAutocompleteEntityValue: function(suggestion) {
    clearAutocompleteSuggestions();
    compoundLeftovers(suggestion.display, suggestion.official_name);
    FacetSearchAffiliatedWithActions.addOrgListRef({ref: suggestion.ref, display: suggestion.official_name});
  },
  onAutocompleteEntity: function(suggestion) {
    clearAutocompleteSuggestions();
    compoundLeftovers(suggestion.display, suggestion.official_name);
    facet_search(suggestion.entity_type, suggestion.official_name);
    return true;
  },
  onAutocompleteLocation: function(suggestion) {
    clearAutocompleteSuggestions();
    //the places service is initialized in mapping.js
    places.getDetails({placeId: suggestion.place_id}, mapZoomToPlace);
    return true;
  },
  onAutocompleteRecentSearch: function(suggestion) {
    FacetSearchStoreActions.setQueryTerm(suggestion.matched);
    doEventSearch();
    return true;
  },
  onAutocompleteSpecialCases: function(suggestion) {
    FacetSearchStoreActions.setQueryTerm(suggestion.matched);
    doEventSearch();
    return true;
  },
  onAutocompleteText: function(suggestion) {
    FacetSearchStoreActions.setQueryTerm(suggestion.matched);
    doEventSearch();
    return true;
  },
  onAutocompleteUserHome: function(suggestion) {
    Zymewire.external_track_action("Autocomplete selection", {
      entity_type: suggestion.type,
      text_chosen: suggestion.display,
      matched: suggestion.matched,
    });

    let suggested = suggestion.type === "source_doc_search" ? suggestion.matched : suggestion.display;

    clearAutocompleteSuggestions();
    compoundLeftovers(suggested, suggestion.official_name, true);

    if(suggestion.type === "entity") {
      facet_search(suggestion.entity_type, suggestion.official_name, false);
    }
    else if(suggestion.type === "entity_value") {
      FacetSearchAffiliatedWithActions.addOrgListRef({ref: suggestion.ref, display: suggestion.display}, false);
    }
    else if(suggestion.type === "alias_search") {
      FacetSearchAliasSearchActions.addAliasSearchRefs({ref: suggestion.ref, display: suggestion.display}, false);
    }
    else {
      FacetSearchStoreActions.setQueryTerm(suggested)
    }
    const searchData = search_data();
    window.location.href = "/facet_search?" + $.param(searchData);
  }
}

function validateQueryTerm(term) {
  if (!quotationsMatched(term)) {
    return { valid: false, errorMsg: "Invalid search: please ensure all quotation marks (\", `) are closed." };
  }
  return { valid: true, errorMsg: null };
}

function quotationsMatched(str) {
  const quotationCharacters = ['"', '`']

  let stack = [];
  for (let c of str) {
    if (!quotationCharacters.includes(c)) continue;

    if (stack[stack.length-1]===c) {
      stack.pop(); // quotation closed
    } else {
      stack.push(c); // new quotation opened
    }
  }
  return stack.length===0;
}

window.FacetSearchStoreActions = FacetSearchStoreActions
export {
  FacetSearchStoreActions,
  FacetSearchStoreMutations
}
