import Reflux from "reflux";
import ContactSearchAction from "../actions/contactSearch/contactSearchActions";

const ContactSearchStore = Reflux.createStore({
  listenables: ContactSearchAction,
  init: function(){
    this.data = {};
  },
  onInitialState: function(data){
    this.data = data;
    //Explicitly set show_gen_email to false, even though undefined is fine
    this.data.show_gen_email = false
    this.trigger(this.data);
  },
  onSearch: function(search_params){
    var url =  [location.protocol, '//', location.host, location.pathname].join('') + "/rolodexes/search";
    this.data.searching = true;
    this.data.check_email_format_result = null;
    $.get(url, search_params, function(response){
      track('zoominfo_search_results', 'Received contact search results', _.extend(search_params, {company_name: this.data.company_name, total_matches: response.max_results}));
      this.data.search_results = response;
      this.data.searching = false;
      this.data.show_gen_email = true;
      this.data.page = 1;
      this.trigger(this.data);
    }.bind(this));
    this.trigger(this.data);
  },
  onBuyContact: function(person_id, localAddress, gdpr_submitted, provider){
    var url =  [location.protocol, '//', location.host, location.pathname].join('') + "/rolodexes/";

    $.post(url, {person_id: person_id, localAddress: localAddress, gdpr_submitted: gdpr_submitted, provider: provider}, function(response){
      $("body").removeClass("wait");

      this.data.user_zinfo = response.user_zinfo;
      this.data.current_contacts = response.current_contacts;
      if (typeof response.current_contacts === 'undefined') {
        alert(response.error);
      } else {
        track('zoominfo_purchased_contact', 'User purchased contact', {company_name: this.data.company_name});
        this.trigger(this.data);
      }
    }.bind(this));
  },
  onCheckDeliverability: function(providerId) {
    var url = [location.protocol, '//', location.host, location.pathname].join('') + "/rolodexes/" + providerId;

    $.ajax(url, { type: 'PUT'} ).then(function(response){
      this.data.user_zinfo = response.user_zinfo;
      this.data.current_contacts = response.current_contacts;
      this.trigger(this.data);
    }.bind(this));
  },
  addContactToSalesforce:function(zi_contact_id, mixpanelOptions){
    if(this.data.use_salesforce_lightning_compatible_form){
      initiateAddToSalesforceModal(null, this.data.org_id, zi_contact_id, mixpanelOptions);
    }
    else{
      triggerSFEngagementActionForClassicMode(mixpanelOptions);
      var url =  [location.protocol, '//', location.host, location.pathname].join('') + "/add_contact_to_sf_modal";
      $.get(url,{zi_person_id: zi_contact_id},
        function (results) {
          $("#event_modal").html(results);
          $("#event_modal").modal();
        }
      );
    }
  },
  onGenerateEmail:function(params){
    let url =  [location.protocol, '//', location.host, location.pathname].join('') + "/rolodexes/generate_email";

    $.get(url, params, function(response){
      $("body").removeClass("wait");
      this.data.check_email_format_result = response;
      this.data.user_zinfo.remaining_for_month = response.remaining_for_month
      this.trigger(this.data);
    }.bind(this));
  },
  onContactAddedToSalesforce: function(response) {
    this.data.user_zinfo = response.user_zinfo;
    this.data.current_contacts = response.current_contacts;

    this.trigger(this.data)
  },
  onLeadAddedToSalesforce: function(response) {
    this.data.user_zinfo = response.user_zinfo;
    this.data.current_contacts = response.current_contacts;

    this.trigger(this.data)
  },
  onClickedEmail: function(){
    track('zoominfo_contact_clicked_email', 'User clicked the email for revealed contact', {company_name: this.data.company_name});
  },
  onClickedPhone: function(){
    track('zoominfo_contact_clicked_phone', 'User clicked the phone number for revealed contact',{company_name: this.data.company_name});
  },
  onChangePage: function(page){
    this.data.page = page;
    this.trigger(this.data)
  },
  onUpdateRevealedContacts: function(currentContacts){
    this.data.current_contacts = currentContacts;
    this.trigger(this.data)
  }
});

window.ContactSearchStore = ContactSearchStore;
export default ContactSearchStore;
