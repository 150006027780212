/*
    SfFormErrorMessage

        Displays an error message

    Required prop:

        errors (object)

            E.g.
            {
                errorCode: {string},
                message: {string}
            }
 */

const SfFormErrorMessage = (props) => {

  const errorText = (errorCode) => {
    const defaultMessage = "Oops. There seems to be an error while creating a record in Salesforce. Please try again later. If the error occurs again please contact support.";
    let message = props.errors.error_messages.join(" and ") || defaultMessage;

    switch(errorCode) {
      case "REQUIRED_FIELD_MISSING":
        message =  "One or more required fields are missing";
        break;
      case "DUPLICATE_VALUE":
        message = "One or more unique fields has a duplicate value";
        break;
      case "JSON_PARSER_ERROR":
        message = "One or more fields has the wrong data type";
        break;
      case "UNKNOWN_EXCEPTION":
        message = "There was an error. Please check for duplicate records or for custom fields in Salesforce.";
        break;
    }
    return message;
  };
  const errorMessages = () => {
    const errorStyle = {
      color: "#FF0000"
    };

    return props.errors.error_codes.map((error, i) => (
      <div key={i}>
        <p style={ errorStyle }>{ errorText(error) }</p>
      </div>
    ))
  };
  return (
    <div className={ props.className }>
      {
        props.errors.error_codes && props.errors.error_codes.length > 0
          ? errorMessages()
          : null
      }
    </div>
  )
};

export default SfFormErrorMessage;
