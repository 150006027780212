import GenericModal from "../generic/modals/genericModal";
import GenericModalActions from "../generic/modals/genericModalActions";
import GenericModalBody from "../generic/modals/genericModalBody";
import GenericModalHeader from "../generic/modals/genericModalHeader";

const SessionTimeoutModal = createReactClass({
  mixins: [
    Reflux.connectFilter(UserStore, 'ttl', function(data) { return data.session.ttl })
  ],
  getInitialState() {
    return { ttl: Infinity, allowModal: true }
  },
  componentDidMount() {
    UserActions.startSessionCheck()
  },
  extendSession() {
    if (this.state.ttl <= 0) {
      window.location.reload()
    } else {
      $.ajax({
        url: '/extend_session',
        method: 'POST',
        success() { UserStore.checkUserSession() }
      })
    }
  },
  signOut() {
    $.ajax({
      url: '/users/sign_out',
      method: 'DELETE',
      success() { window.location.reload() }
    })
  },
  onCancel() {
    this.setState({ allowModal: false })
  },
  render() {
    const modalOpen = this.state.allowModal && this.state.ttl <= 60
    const forceSignOut = this.state.ttl <= 0

    return (
      <GenericModal open={modalOpen || forceSignOut} style={{ minWidth: '400px' }}>
        <GenericModalHeader>
          { forceSignOut ? 'Session Expired' : 'Session Expiration Warning' }
          <div style={{flexGrow: 1}}></div>
          {
            !forceSignOut &&
            <div
              id="dismiss_session_timeout"
              className="close_x"
              style={{ position: 'unset' }}
              onClick={this.onCancel}
            >
            </div>
          }
        </GenericModalHeader>

        <GenericModalBody>
          {
            forceSignOut ?
              'Your session has expired.':
              `Your session will expire in ${this.state.ttl} seconds. Do you want to extend the session?`
          }
        </GenericModalBody>

        <GenericModalActions>
          <div style={{ flexGrow: 1 }} />
          <button
            id="extend_session"
            className="button-small button-primary"
            onClick={this.extendSession}
          >
            { forceSignOut ? 'Reload Page' : 'Extend Session' }
          </button>

          {
            !forceSignOut &&
            <button
              id="logout_session_timeout"
              className="button-small button-critical TEMPORARY-MARGIN-LEFT-SMALL"
              onClick={this.signOut}
            >
              Logout Now
            </button>
          }
        </GenericModalActions>
      </GenericModal>
    )
  }
})

window.SessionTimeoutModal = SessionTimeoutModal
export default SessionTimeoutModal;
