import ComponentFromHTML from "@javascript/reactComponents/shared/ComponentFromHTML";
import Button from "@javascript/reactComponents/lds/Button";
import ButtonGroup from "@javascript/reactComponents/lds/ButtonGroup";

import Heading from "@javascript/reactComponents/lds/typography/Heading";
import Icon from "@javascript/reactComponents/lds/Icon";
import LegacyModalService from "@javascript/globals/LegacyModalService";

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@javascript/reactComponents/lds/modal/Modal";

import NavItem from "@javascript/reactComponents/lds/NavItem";


function SuccessContent({ feedActionType, newFeedId }) {
  const goToFeed = () =>
    location.assign(`/feeds/${newFeedId}/display_saved_feed`);

  let title = "New custom feed";
  let heading = "Feed saved successfully!"

  switch (feedActionType) {
    case "new":
    case "create":
      break;
    case "list":
      title = "New feed from your list"
      break;
    case "clone":
      title = "Copy this feed as..."
      heading = "Feed copied successfully!"
      break;

    default:
      break;
  }

  return (
    <>
      <ModalHeader>{title}</ModalHeader>

      <ModalBody className="flex-col align-center justify-center">

        <Icon
          name={"special-success"}
        />

        <Heading size="h2">{heading}</Heading>

        <div className="flex-row align-center">
          <span>You can also access all your feeds on &nbsp;</span>
          <NavItem
            id="feed-modal-to-feed-page-link"
            href="/feeds"
            className="feeds_modal_nav_link hovered"
            icon="feeds"
            variant="link"
          >
            Feeds
          </NavItem>
          <span>&nbsp; page.</span>
        </div>
      </ModalBody>

      <ModalFooter align="flex-end">
        <ButtonGroup size="medium">
          <Button
            size="medium"
            variant="secondary"
            onClick={LegacyModalService.closeModal}
          >
            Close
          </Button>
          <Button size="medium" variant="primary" onClick={goToFeed}>
            Go to Feed
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </>
  );
}

export default function LDSFeedSaveModal({
  feedActionType,
  newFeedId,
  legacyModalContent,
}) {
  return (
    <Modal
      open={true}
      onClose={LegacyModalService.closeModal}
    >
      {legacyModalContent ? (
        <ComponentFromHTML html={legacyModalContent} />
      ) : (
        <SuccessContent feedActionType={feedActionType} newFeedId={newFeedId} />
      )}
    </Modal>
  );
}

window.LDSFeedSaveModal = LDSFeedSaveModal;
