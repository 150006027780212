import CompanySfIcon from "../companySfIcon";
import HeaderRow from "./headerRow";

let hacky_timer;

const CompanyRestore = (props) => {
  const restore_company = function() {
    RowRemovalActions.restoreCompany(props.company_data.id)
  }

  return (
    <div onClick={restore_company} className="company_added_removed_div company_removed_div">undo</div>
  );
}

const CompanyRemove = (props) => {
  const remove_company = function() {
    RowRemovalActions.removeCompany(props.list_id,props.company_data.id)
  }

  return (
    <div onClick={remove_company} className="company_added_removed_div company_removal_div">remove</div>
  );
}

const CompanyAdded = (props) => {
  const remove_company = function() {
    RowRemovalActions.removeCompany(props.list_id,props.company_data.id)
  }

  const added_date = moment.parseZone(props.company_data.added).format('MMM DD YYYY');
  return (
    <div onClick={remove_company} className="company_added_removed_div company_added_div">Added: {added_date}</div>
  );
}

const CompanyRow = createReactClass({
  mixins: [Reflux.listenTo(CompanySettingsStore,"update_settings"), Reflux.listenTo(RowHoverStore,"update_hover_state"), Reflux.listenTo(RowRemovalStore,"update_removal_state")],
  getInitialState: function(){
    return {
      company_settings:[{current_sort:false, default_sort:'null'}],
      hover_id:false,
      hover_state:false,
      removal_state:false
    }
  },
  update_settings: function(company_settings) {
    this.setState({
      company_settings: company_settings
    });
  },
  update_hover_state: function(hover_id) {
    if(hover_id === this.props.company_data.id){
      this.setState({
        hover_state: true
      });
    }
    else{
      this.setState({
        hover_state: false
      });
    }
    this.setState({
      hover_id: hover_id
    });
  },
  update_removal_state: function(removal_ids) {
    if(removal_ids.includes(this.props.company_data.id)){
      this.setState({
        removal_state: true
      });
    }
    else{
      this.setState({
        removal_state: false
      });
    }
  },
  mouse_over (e) {
    clearTimeout(hacky_timer);
    RowHoverActions.updateHover(this.props.company_data.id)
  },
  mouse_out (e) {
    hacky_timer = setTimeout(function() { RowHoverActions.updateHover(null) }, 71);
  },
  manage_lists (e){
    const id = "list_manage_" + this.props.company_data.id;
    display_list_modification_widget($('#' + id));
    return false;
  },
  render: function () {
    const hover_state = this.state.hover_state === true ? ' list_entry_hover' : '';
    let company_name = this.props.company_data.name;
    if (company_name.length > 40) {
      company_name = company_name.substr(0,40-3) + "...";
    }
    // Last visited feature
    const last_visited = this.props.company_data.last_visited;
    // Link tooltip
    const default_tooltip = "Go to the " + company_name + " dashboard.";
    const last_visited_tooltip = "Last visited " + company_name + " dashboard " + last_visited + " ago.";
    const tooltip = last_visited ? last_visited_tooltip : default_tooltip;
    // CSS link styling
    const default_link_classes = "fancy_link event_organization";
    const last_visited_classes = default_link_classes + " visited_org_link"
    const company_link_classes = last_visited ? last_visited_classes : default_link_classes;

    const company_id = this.props.company_data.id;
    const view_url = this.props.company_data.view_url;
    let sponsor_lit = 'unlit';
    let a_sponsor = ' NOT';
    if(this.props.company_data.sponsor === true){
      sponsor_lit = 'lit';
      a_sponsor = ''
    }
    let company_added;
    let company_removed = '';
    if(this.state.removal_state === true){
      if(this.state.hover_state === this.props.company_data.id){
      }
      company_added = <CompanyRestore {...this.props} />;
      company_removed = ' company_removed_fade'
    }
    else{
      if(this.state.hover_id === this.props.company_data.id && this.props.is_editable){
        company_added = <CompanyRemove {...this.props} />
      }
      else{
        company_added = <CompanyAdded {...this.props} />
      }
    }
    let company_sf = '';
    if(this.props.type_ref === "organizations" && this.props.display_sf){
      company_sf = <CompanySfIcon {...this.props} />
    }

    let class_names = (this.state.company_settings[0].css_classes) ?  [this.state.company_settings[0].css_classes] : [];
    class_names.push('list_cell');
    class_names.push('list_company_cell');

    class_names.push('sorted_by_' + this.state.company_settings[0].current_sort);
    class_names.push('default_sorted_by_' + this.state.company_settings[0].default_sort);

    if(this.props.is_editable === false && !_.include(class_names[0], 'fullstory_excluded')){
      class_names.push('fullstory_excluded');
    }

    let entityNameStyle = { marginLeft: "90px" };

    if (this.props.type_ref !== "organizations") {
      entityNameStyle = {
        marginLeft: "0px"
      }
    }

    return (
      <div onMouseEnter={this.mouse_over} onMouseLeave={this.mouse_out} className={"list_column_row list_entry_row" + hover_state} >
        <div className={class_names.join(' ')}>
          <div className="list_company_data">
            <div className="list_organization_data event_organizations list_organizations">
              {this.props.type_ref === "organizations" && <span className={"organization_data_icons list_organization_icons" + company_removed}>
                  <div className="rspec_sponsor_icon list_view_icon_div list_sponsor_icon" title={company_name + " is" + a_sponsor + " a sponsor."}>
                      <div className="ep_icon_flipper">
                        <div className={"ep_icon_front list_sponsor_" + sponsor_lit}></div>
                        <div className="ep_icon_back ep_icon_close"></div>
                      </div>
                  </div>
                  {company_sf}
                  <div className="rspec_list_icon ep_list_menu" title={"Click to manage the lists associated with " + company_name + "."}>
                    <a data-list-origin="pivotlist" data-list-id={this.props.list_id} data-company-id={company_id} id={"list_manage_" + company_id} data-company-name={company_name} onClick={this.manage_lists} className="btn btn-mini inline-btn-icon company_list_menu pop_trigger ep_lists_lit">
                      <img src="/images/listediticon2_alt.png" className="company_list"/>
                    </a>
                  </div>
                </span>
              }
              {company_added}
            </div>
            <div className={"organization_details organization_list_details" + company_removed} style={entityNameStyle}>
              <a
                onMouseOver={() => {
                  delayed_track(
                    "Mouse over entity (last visited)",
                    { entity_id: company_id, entity_name: company_name },
                    { reset_scope: "btst" }
                  )}}
                onMouseOut={() => reset_delayed_track("btst") }
                href={view_url}
                className={company_link_classes}
                data-container="body" title={tooltip}>
                  {company_name}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
});

const CompanyRows = createReactClass({
  mixins: [Reflux.listenTo(pivotListColumnSettingsStore, 'updateContainerHeightState'), Reflux.listenTo(ColumnControlsStore,"toggleControlsState")],
  getInitialState: function(){
    return {
      controls_open: this.props.controls_open,
      listTableContainerHeight: pivotListColumnSettingsStore.state.listTableContainerHeight
    }
  },
  updateContainerHeightState: function(newState) {
    this.setState({
      listTableContainerHeight:newState.listTableContainerHeight
    });
  },
  toggleControlsState: function(controlsOpen) {
    this.setState({
      controls_open: controlsOpen
    });
  },
  componentDidMount: function() {
    this.scroller.addEventListener("wheel", this.scrollController);
  },
  componentWillUnmount: function() {
    this.scroller.removeEventListener("wheel", this.scrollController);
  },
  scrollController: function(e) {

    if(this.state.controlsOpen === 'checked') {
      return;
    }

    let delta = e.wheelDelta || -e.detail;
    let shiftKey = e.shiftKey;

    if(shiftKey === true) {
      const listEntriesScrollHeader = document.getElementById("list_entries_scroll_header");
      ColumnControlsActions.scrollWheelControls(delta, shiftKey, listEntriesScrollHeader);
    }
    else {
      ColumnControlsActions.scrollWheelControls(delta, shiftKey, this.scroller.scrollTop);
    }

  },
  render: function () {

    const companies = this.props.list_object.list_entries.map(function(entry, index){
      if(entry[0]){
        return <CompanyRow key={index} company_data={entry[0]} type_ref={this.props.list_object.type_ref} list_id={this.props.list_object.list_id} display_sf={this.props.display_sf} is_editable={this.props.is_editable} />
      }
      else {

        const class_names = ['list_company_cell', 'list_cell', 'list_empty_cell'];

        return (
          <div key={index} className="list_column_row list_empty_row">
            <div className={class_names.join(' ')}>&nbsp;</div>
          </div>
        )
      }
    }.bind(this));

    const class_names = ['list_companies_container'];

    if(this.state.controls_open === 'checked'){
      class_names.push('desat_list_companies_container');
    }
    const listTableContainerHeight = this.state.listTableContainerHeight - 69
    const istTableContainerHeightInPx = listTableContainerHeight + 'px';
    const adjustedStyle = {maxHeight: istTableContainerHeightInPx, height: istTableContainerHeightInPx, minHeight: istTableContainerHeightInPx, overflow: 'hidden'};

    const header = <HeaderRow {...this.props} header_type="list_company_header"/>;
    return (
      <div className={class_names.join(' ')} >
        {header}
        <div ref={elem => this.scroller = elem} id='list_companies_scroll_data' className="list_companies_scroll_data" style={adjustedStyle}>
          {companies}
        </div>
      </div>
    );
  }
});

export default CompanyRows;
