const SfIndexRow = createReactClass({
  jumpHere: function (e){
    e.stopPropagation();
    e.preventDefault();
    SFPanelActions.jump(this.props.account_id);
  },
  render: function () {
    const classes = {owner:'',opps:'',last_activity:''};
    let link = <a className="no_link">{this.props.account_name}</a>;
    let owner = "-";
    let opps = "-";
    let last_activity = "-";
    if(this.props.owner !== false){
      owner = this.props.owner;
      classes.owner = "fade_in";
    }
    if(this.props.opps !== false){
      opps = this.props.opps;
      if(opps > 24){
        opps = "24+";
      }
      classes.opps = "fade_in";
    }
    if(this.props.last_activity !== false){
      last_activity = moment(this.props.last_activity).add(12, 'h').format("dddd, MMMM D, YYYY");
      classes.last_activity = "fade_in";
    }
    if(this.props.loaded !== false){
      link = <a onClick={this.jumpHere} className="fancy_link local_link">{this.props.account_name}</a>;
    }
    return (
      <div className="div_table_row w-1200">
        <div className="div_table_cell sf_accounts_cell sf_accounts_column">{link}</div>
        <div className="div_table_cell sf_accounts_cell sf_accounts_column div_table_cell_padded"><span className={classes.owner}>{owner}</span></div>
        <div className="div_table_cell sf_accounts_cell sf_accounts_column div_table_cell_padded"><span className={classes.opps}>{opps}</span></div>
        <div className="div_table_cell sf_accounts_cell sf_accounts_column div_table_cell_padded"><span className={classes.last_activity}>{last_activity}</span></div>
      </div>
    );
  }
});

export default SfIndexRow;
