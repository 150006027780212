import { useContext, useState } from "react";

import Chip from "../../../lds/chips/Chip";
import ListName from "./ListName";
import { DetailsSectionContext } from "./DetailsSection";
import PagedEntityListPreview from "./PagedEntityListPreview";

const PreviewSection = () => {
  const { entityCountDetail } = useContext(DetailsSectionContext);
  const [showEntityListPreview, setShowEntityListPreview] = useState(false);

  const toggleEntityListPreview = () =>
    setShowEntityListPreview(!showEntityListPreview);

  if (!entityCountDetail) {
    return null;
  }

  return (
    <div style={{ marginBottom: "32px", fontSize: "16px" }}>
      Your list will be generated from the
      <span style={{ margin: "0 5px" }}>
        {entityCountDetail.all_entities.length}{" "}
        <Chip
          style={{
            display: "inline",
            color: "var(--chip-color-text-entity)",
            backgroundColor: "var(--chip-color-bgFill-entity)",
            marginLeft: "5px",
          }}
          label={entityCountDetail.category}
          size={"small"}
        />
      </span>
      in your search (ranked by event count).&nbsp;
      <a
        onClick={toggleEntityListPreview}
        className="action-link"
        id="entity-list-preview-toggle"
      >
        {showEntityListPreview ? "Hide Preview" : "Show Preview"}
      </a>
      <div style={{ marginTop: "var(--space-600)" }}></div>
      {showEntityListPreview && <PagedEntityListPreview entityList={entityCountDetail.all_entities} />}
      <ListName />
    </div>
  );
};

export default PreviewSection;
