import { useState } from "react";
import PropTypes from "prop-types";

import Input from "@javascript/reactComponents/lds/Input"

import {fieldInError, commonFieldElements} from "@javascript/helpers/crmUtilities";

const CRMInput = ({
  errors,
  field,
}) => {

  const [useValue, setValue] = useState(field.value ? field.value : "");

  let isMissing = fieldInError(errors, field.name);
  const { helperText, helperTextVariant, placeholder } = commonFieldElements(field, isMissing);

  const disabled = field.name === "AccountId";

  let multiLine = false;
  let height = "";
  if(field.type === "textarea") {
    multiLine = true;
    height = "var(--input-size-height-large)";
  }

  return (
    <Input
      className={"fullstory_excluded"}
      disabled={disabled}
      height={height}
      helperText={helperText}
      helperTextVariant={helperTextVariant}
      multiLine={multiLine}
      name={field.name}
      onValueChange={setValue}
      placeholder={placeholder}
      size="large"
      value={useValue || ""}
      width={"100%"}
    />
  );
};

CRMInput.propTypes = {
  errors: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object,
  ]),
  field: PropTypes.object.isRequired,
};

export default CRMInput;
