import CopyToClipBoardButton from '../generic/copyToClipboardButton';
import FlagContactButton from "./flagContactButton";

const DeliverabilityMessage = createReactClass({
  isApolloActive: function () {
    return _.contains(Zymewire.user.feature_tags, "can_use_apollo_contact_search");
  },

  deliverabilityIndicator: function (deliverability) {
    const UNDELIVERABLE = {
      color: 'red',
      text: 'No credits deducted. This email address is not active. For more details check out our help section',
      textDecoration: 'line-through',
      icon: 'fa fa-close'
    };
    const DEFAULT = {
      color: '',
      text: '',
      textDecoration: 'none',
      icon: 'fa'
    };

    // we need to do this since DeliverabilityMessage is being used in generate email component
    // and we should not be showing the flag button in generate email.
    let passThese = {}

    if (!this.props.generateEmailSection) {
      passThese = {
        entityId: this.props.current_contact.entity_id,
        orgName: this.props.company.name,
        organizationId: this.props.org_id,
        contactId: this.props.provider_id,
        personId: this.props.entity_id,
        deliverability: this.props.deliverability,
        flagged: this.props.flagged
      };
    }

    switch (deliverability) {
      case 'deliverable':
        return this.deliverable(passThese);
      case 'undeliverable':
        return UNDELIVERABLE;
      case 'risky':
        return this.risky(passThese);
      default: {
        return DEFAULT;
      }
    }
  },

  deliverable: function(passThese) {
    var text;

    if (!this.isApolloActive() && !this.props.generateEmailSection) {
      text = this.props.revealedSection ? <FlagContactButton {...passThese} /> : '';
    } else if(this.isApolloActive()){
      text = "";
    } else {
      text = 'This email address is active. Go ahead and reach out. For more details check out our help section.';
    }

    return {
      color: 'green',
      text: text,
      textDecoration: 'none',
      icon: 'fa fa-check'
    }
  },

  risky: function(passThese) {
    var text;

    if (!this.props.generatedEmail) {
      return this.deliverable(passThese);
    }

    if (!this.isApolloActive() && !this.props.generateEmailSection && !this.props.generatedEmail) {
      text = this.props.revealedSection ? <FlagContactButton {...passThese} /> : '';
    } else if(this.isApolloActive()){
      text = "";
    } else {
      text = 'No credits deducted. This email address may or may not be active. For more details check out our help section';
    }

    return {
      color: 'orange',
      text: text,
      textDecoration: 'none',
      icon: 'fa fa-exclamation'
    }
  },

  clickedEmail: function(e){
      ContactSearchAction.clickedEmail();
  },

  render: function () {

    let deliverability = {text: this.props.loading_text}; //text field can be undefined initially.
    let email = this.props.email;
    let deliverability_result = this.props.deliverability_result;

    let score = this.props.score;
    let on_click_email = this.clickedEmail;
    let mail_to = "mailto:" + email;

    let email_detail;
    let deliverability_score_message;

    let deliverability_checker;
    let is_loading = !this.props.deliverability_result;
    let clipboard_uid = this.props.entity_id

    if (this.props.revealedSection){
      clipboard_uid += '_revealed_section'
    }

    let copyIcon = <CopyToClipBoardButton text={email} uid={clipboard_uid} />

    if (deliverability_result) {
      let deliverability_icon_style = {
        marginLeft: '5px',
        fontSize: '14px'
      };

      deliverability = this.deliverabilityIndicator(deliverability_result);

      email_detail = (
        <div title={deliverability.text} data-deliverability={deliverability_result}>
          <a href={mail_to} target="_blank" className="dont_stop cs_revealed_email fullstory_excluded"
             style={{color: deliverability.color, textDecoration: deliverability.textDecoration}}
             onClick={on_click_email}>
            {email}
          </a>
          <i className={deliverability.icon} style={deliverability_icon_style}></i>
          {copyIcon}
        </div>
      );

      deliverability_checker = <p className="cs_deliverability_check">{deliverability.text}</p>;

    }
    else {
      email_detail = (
        <div>
          <a href={mail_to} target="_blank" className="dont_stop cs_revealed_email fullstory_excluded"
             onClick={on_click_email}>
            {email}
          </a>
          {copyIcon}
        </div>
      );

      deliverability_checker = [
        <p className="cs_deliverability_check">
          {deliverability.text}
          <i className="fa fa-refresh fa-spin fa-fw"></i>
        </p>,
      ];
    }

    return (
      <div>
        {email_detail}
        {deliverability_score_message}
        <br/>
        {deliverability_checker}
      </div>
    )
  }
})

export default DeliverabilityMessage;
