import React, { useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import Icon from "../Icon";
import { TabContext } from "./TabGroup";
import { TabbedViewContext } from "./TabbedView";

export default function Tab({
  id,
  label,
  value,
  component,
  leadingIcon = "",
  selected = false,
  onTabClicked = () => {},
}) {
  const { selectedTab, onTabSelected, updateIndicator } =
    useContext(TabContext);
  const tabRef = useRef(null);
  const isSelected = selected || value === selectedTab;

  // Use a Component Registry to keep track of the tabs and the components they should render
  const { registerComponent } = useContext(TabbedViewContext);

  useEffect(() => {
    registerComponent(value, component);
  }, [value, component]);

  // Trigger the tab selection if/when the tab changes size to keep the tab indicator on the parent updated
  const handleResize = () => {
    if (isSelected) updateIndicator(tabRef);
  };

  // ResizeObserver is undefined in Jest and we don't need it for the unit tests
  useEffect(() => {
    if (window.ResizeObserver) {
      const observer = new ResizeObserver(handleResize);
      observer.observe(tabRef.current);

      return () => {
        observer.unobserve(tabRef.current);
      };
    }
  }, []);

  // Handle the click before bubbling
  const clickTab = (event) => {
    onTabClicked(event);

    onTabSelected(value, tabRef);
  };

  return (
    <span
      id={id}
      ref={tabRef}
      className={`lds-tab ${isSelected ? "lds-tab-selected" : ""}`}
      onClick={(event) => isSelected || clickTab(event)}
      role="tab"
      aria-selected={isSelected ? "true" : "false"}
    >
      {leadingIcon && (
        <span data-testid={`lds-tab-${value}-icon`}>
          <Icon name={leadingIcon} filled={isSelected} />
        </span>
      )}
      {label}
    </span>
  );
}

Tab.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,

  component: PropTypes.func,
  leadingIcon: PropTypes.string,
  selected: PropTypes.bool,
};
