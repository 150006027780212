/**
 * React component that displays a link to unfurl more items in a list
 *
 * @author
 * @namespace
 * @memberof
 *
 * @component
 * @example
 * return (
 *   <FacetSectionShowMore
 *      facetType={facetType}
 *      bucketLength={list.length}
 *      addMoreFacets={some.function}
 *      displayCount={displayCount}
 *   />
 * )
 *
 * @param {String} facetType the type of facet this link is being used with, currently limited to: "conditions", "organizations", and "therapies"
 * @param {Number} bucketLength the length of the list of items to show
 * @param {Function} addMoreFacets unveil more of the list
 * @param {Number} displayCount the maximum amount of items to display before a call to action is displayed instead of "... show more"
 *
 */

const FacetSectionShowMore = createReactClass({
  componentDidUpdate: function() {
    const showMoreWrapper = $(this.refs.showMoreWrapper);
    const facetType = this.props.facetType;
    const displayCount = this.props.displayCount;

    const over_30 = showMoreWrapper.find("#" + facetType + "_showing_30");
    const link_id = showMoreWrapper.find("#" + facetType + "_more");

    displayCount >= this.props.bucketLength ? link_id.hide() : link_id.show();
    displayCount == 30 && typeof Zymewire.is_mapping_page == "undefined"
      ? over_30.fadeIn(1000)
      : over_30.hide();
  },

  render: function() {
    const facetType = this.props.facetType;
    const bucketLength = this.props.bucketLength;

    let furtherText = <p id={facetType + "_showing_30"} style={{display:'none'}}>Showing top 30: try narrowing your search</p>;
    let getMoreButton;
    let data = search_data();

    if (bucketLength > 10) {
      getMoreButton = (
        <a
          style={{ cursor: this.props.isLoading ? "wait" : "default" }}
          id={facetType + "_more"}
          href="#"
          className="show_more_link"
          onClick={(event) => this.props.addMoreFacets(event)}
        >
          ...more
        </a>
      );
    }

    if(!(empty_search(data))){
      furtherText = (<p id={facetType + "_showing_30"} style={{display: 'none'}}>
        <a href="#" onClick={() => createListFromQuery()}>More than 30... click to create a list</a>
      </p>);
    }

    return (
      <div ref="showMoreWrapper">
        {getMoreButton}
        {furtherText}
      </div>
    )
  }
});

export default FacetSectionShowMore;
