import SfFormFieldsCore from "./sfFormFieldsCore";
import SfFormFieldsOptional from "./sfFormFieldsOptional";
import SfFormErrorMessage from "./sfFormErrorMessage";
import SfFormWaitingMessage from "./sfFormWaitingMessage";

import _ from "underscore";

const SfLeadForm = createReactClass({
  getInitialState(){
    return {
      contactSfLink: null,
      coreFields: null,
      created: false,
      errorBuildingForm: false,
      errors: false,
      optionalFields: null,
    };
  },
  retrieveSfLeadFields(){
    let url = "/company/" + this.props.org_id + "/retrieve_salesforce_lead_fields";
    let data = { zi_contact_id: this.props.zi_contact_id };
    let self = this;

    $.ajax({
      url: url,
      type: "GET",
      data: data,
      headers: {
        // form_authenticity_token TODO: this is ignored, yet somehow the header is still set (deprecate this header and it still works)
        "X-CSRF-Token": "<%= form_authenticity_token.to_s %>"
      },
      success: function(results) {
        const validFields = crmHelpers.pruneInvalidFields(results.fields);
        const [coreFields, optionalFields] = _.partition(validFields, (field) => field.opt_custom !== true);
        self.setState({
          coreFields: coreFields,
          optionalFields: optionalFields,
        });
      },
      error: function(e){
        console.error(e);
        self.setState({ errorBuildingForm: true });
      },
      statusCode: {
        401: () => { 
          location.reload()
        }
      }
    });
  },
  cancel(){
    SalesforceFormActions.close();
  },
  createLead(e) {
    e.preventDefault();
    const data = _.object(_.map( $(".sf-account-form").serializeArray(), _.values));
    data["org_id"] = this.props.org_id;

    const url = "/salesforce/leads/";
    let self = this;

    $.ajax({
      url: url,
      type: "POST",
      data: data,
      headers: {
        // form_authenticity_token TODO: this is ignored, yet somehow the header is still set (deprecate this header and it still works)
        "X-CSRF-Token": "<%= form_authenticity_token.to_s %>"
      },
      success: function(results) {
        let params = data;

        if (results.response.status === 400) {
          self.setState({ errors: {error_fields: results.error_fields, error_codes: results.error_codes, error_messages: results.error_messages} });
        }
        else {

          let contactSfLink = null;

          if (Zymewire.user.feature_tags.includes("rolodex_v3")){
            const contacts = {
              user_zinfo: results.user_zinfo,
              current_contacts: results.current_contacts
            };

            let sfAddedLead = contacts.current_contacts.find(contact =>
              (contact.email === params.Email) || (params.Phone !== "" && contact.phone.mobile === params.Phone)
            );

            if (sfAddedLead) {
              contactSfLink = sfAddedLead.sales_force_leads[0].sf_url;
            }

            ContactSearchAction.leadAddedToSalesforce(contacts);
          }
          self.setState({ errors: false, created: true, created_data: results, contactSfLink: contactSfLink});
          SFPanelWrapperActions.updateLeads(results.leads);
        }
      }
    });
  },

  // Renders the form entities
  // In the initial state it checks if state.coreFields has value:
  // IF IT DOES => Render the form with the fields
  // IF NOT     => Then call retrieve() fields to get the required fields from the Restforce API
  //               and display a message to the user informing them to wait while the form is
  //               being generated
  render() {
    const formStyle = {
      width: "100%",
      margin: "0 auto"
    };

    let contactSfLink = null;
    if (this.state.contactSfLink){
      contactSfLink = <a target="_blank" href={this.state.contactSfLink}>View record in Salesforce</a>;
    }

    if (this.state.coreFields) {
      if (this.state.created) {
        return (
          <div>
            <div className="modal-body">
              <div style={formStyle}>
                <h3>Successfully created a new Lead in your Salesforce</h3>
                { contactSfLink }
              </div>
            </div>
            <div className="modal-footer">
              <button type="reset" className="button-small button-primary" data-dismiss="modal">Close</button>
            </div>
          </div>
        )
      }
      else {
        return (
          <div>
            <form className="sf-account-form">
              <div className="modal-body">
                <div style={formStyle}>
                  <SfFormFieldsCore
                    errors={this.state.errors}
                    fields={this.state.coreFields}
                  />
                  <SfFormFieldsOptional
                    errors={this.state.errors}
                    fields={this.state.optionalFields}
                  />
                </div>
              </div>
              <div id="new_feed_footer" className="modal-footer container-fluid" style={{textAlign: "unset"}}>
                <div className="row-fluid">
                  <SfFormErrorMessage errors={ this.state.errors } className="span7"/>
                  <div className="span5">
                    <button type="reset" className="button-small button-secondary" data-dismiss="modal">Cancel</button>
                    <button type="submit" className="button-small button-primary button_spaced_10_left" onClick={this.createLead}>Add Lead</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        )
      }
    } else {
      if (this.state.errorBuildingForm) {
        return (
          <div><p>There was an issue building the form. Please try again later.</p></div>
        )
      } else {
        this.retrieveSfLeadFields();
        return <SfFormWaitingMessage />
      }
    }
  }
});

export default SfLeadForm;
