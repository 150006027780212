import SfContactButton from "./sfContactButton";

const SfFormSelector = createReactClass({
  getInitialState() {
    return {
      salesforce_accounts: null
    }
  },
  getSalesforceAccounts() {
    let url = "/company/" + this.props.org_id + "/retrieve_salesforce_accounts";
    let self = this;

    $.ajax({
      url: url,
      type: "POST",
      data: { org_id: this.props.org_id },
      headers: {
        // form_authenticity_token TODO: this is ignored, yet somehow the header is still set (deprecate this header and it still works)
        "X-CSRF-Token": "<%= form_authenticity_token.to_s %>"
      },
      success: function(results) {
        if (results.response === false) {
          alert("There was an error generating the form");
        } else {
          self.setState({ salesforce_accounts: results });
        }
      },
      error: function(e) {
        console.error(e);
        if(e.status === 401){
          location.reload();
        }
      }
    });
  },
  choseLead: function(e){
    e.preventDefault();
    this.props.parent_method("lead", null);
  },

  render() {
    const holderStyle = {
      margin: "20px"
    };
    const buttonPad = {
      marginBottom: "20px"
    };
    if (this.state.salesforce_accounts) {
      let self = this;
      return (
        <div style={holderStyle}>
          {
            this.state.salesforce_accounts.sf_accounts.map(function(i){
              return (
                <SfContactButton {...i} parent_method={this.props.parent_method} />
              )
            }.bind(this))
          }
          <div>
            <button type="submit" style={buttonPad} className="button-small button-primary" onClick={this.choseLead}>
              <span>Create New <b> Lead</b></span>
            </button>
          </div>
          <div><button type="submit" style={buttonPad} className="button-small button-secondary right" data-dismiss="modal">Cancel</button></div>
        </div>
      )
    } else {
      this.getSalesforceAccounts();
      return (
        <div>
          <p>Retrieving Salesforce account information, please wait ...</p>
        </div>
      )
    }
  }
});

export default SfFormSelector;
