const SfFormWaitingMessage = () => {
  const formStyle = {
    width: "100%",
    margin: "0 auto",
    padding: "20px"
  };
  return (
    <div style={formStyle}>
      <p>Please wait while we generate the form ...</p>
    </div>
  )
};

export default SfFormWaitingMessage;
