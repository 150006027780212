import DownloadPrimoPlModalBody from "./downloadPrimoPlModalBody";
import DownloadPrimoPlModalHeader from "./downloadPrimoPlModalHeader";

const DownloadPrimoPlModal = createReactClass({
  getInitialState: function(){
    return {choice:null,checked:false}
  },
  update_choice: function(choice){
    this.setState({choice: choice, checked:true});
    return false;
  },
  submit_form: function(e){
    e.stopPropagation();
    e.preventDefault();
    $("body").addClass("wait");
    $.ajax({
      url: "/lists/" + this.props.list._id + "/email_user_list_download?list_type=" + this.state.choice,
      type: 'GET',
      headers: {
        'X-CSRF-Token': this.props.authenticity_token
      },
      success: function (result) {
        Zymewire.mixpanel_track('build campaign', {type: this.state.choice});
        $("body").removeClass("wait");
        if (result.confirmation_display === null) {
          close_modal_control();
        }
        else {
          replace_modal_contents(result.confirmation_display);
        }
      }.bind(this)
    });
    return false;
  },
  render: function(){
    return (
      <div className="modal_wrapper">
        <DownloadPrimoPlModalHeader />
        <DownloadPrimoPlModalBody {...this.props} checked={this.state.checked} choice={this.state.choice} update_choice={this.update_choice} submit_form={this.submit_form}/>
      </div>
    );
  }
});

window.DownloadPrimoPlModal = DownloadPrimoPlModal;
export default DownloadPrimoPlModal;
