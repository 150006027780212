import Reflux from "reflux";
import UserListsCreateModalActions from "../actions/lists/UserListCreateModalActions";
import qs from "qs";
import {
  LIST_CREATE_REQUEST_FAILURE,
  LIST_CREATE_REQUEST_STARTED,
  LIST_CREATE_REQUEST_SUCCESS,
  LIST_CREATE_REQUEST_UNSTARTED,
} from "../../constants/lists/list_constants";

const UserListCreateModalStore = Reflux.createStore({
  listenables: [UserListsCreateModalActions],

  init() {
    this.data = {
      baseQuery: {},
      createListRequestStatus: LIST_CREATE_REQUEST_UNSTARTED,
      entityCountsDetails: {},
      isModalOpen: false,
      categoryListNames: {},
      listName: null,
      mapDisplay: false,
      query: {},
      showDetails: false,
      sourceSavedFeedId: null,
    };
  },

  setSourceSavedFeed(feedId) {
    this.data.sourceSavedFeedId = feedId;
  },

  getInitialState() {
    this.onInitialState();
  },

  async onInitialState() {
    let data = search_data();

    if (empty_search(data)) {
      return false;
    }

    if (current_bounding_box() != null) {
      data.geo_box = current_bounding_box();
    }

    if (this.data.sourceSavedFeedId) {
      data.saved_feed_id = this.data.sourceSavedFeedId;
    }

    const response = await fetch(
      `/user_lists/new?${qs.stringify(data, { arrayFormat: "brackets" })}`
    );

    if (response.ok) {
      const res = await response.json();

      _.each(res.entity_counts_details, (value, key) => {
        this.data.entityCountsDetails[value.type_ref] = value;
      });

      this.data.query = res.query;
      this.data.baseQuery = res.base_query;
      this.data.categoryListNames = res.category_list_names
      this.data.mapDisplay = res.map_display;
      this.data.showDetails = true;

      this.trigger(this.data);
    } else {
      console.error(response);
    }
  },

  resetState() {
    this.data = {
      isModalOpen: false,
      entityCountsDetails: [],
      query: {},
      listName: "",
      categoryListNames: {},
      baseQuery: {},
      mapDisplay: false,
      showDetails: false,
      createListRequestStatus: LIST_CREATE_REQUEST_UNSTARTED,
      sourceSavedFeedId: null,
      createdListId: null,
    };
  },

  setOpenModal() {
    this.data.isModalOpen = true;
    this.trigger(this.data);
  },

  onListNameChange(name) {
    this.data.listName = name;
    this.trigger(this.data);
  },

  async onCreateList(data) {
    this.data.createListRequestStatus = LIST_CREATE_REQUEST_STARTED;
    this.data.showDetails = false;
    this.trigger(this.data);

    const csrfToken = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");

    const response = await fetch("/user_lists", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    });

    if (response.ok) {
      const responseData = await response.json()

      this.data.createdListId = responseData.list_id
      this.data.createListRequestStatus = LIST_CREATE_REQUEST_SUCCESS;
      this.trigger(this.data);
    } else {
      this.data.createListRequestStatus = LIST_CREATE_REQUEST_FAILURE;
      this.trigger(this.data);
    }
  },
});

window.UserListCreateModalStore = UserListCreateModalStore;
export default UserListCreateModalStore;
