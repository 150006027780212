const SfContactButton = (props) => {
  const handleClick = (e) => {
    e.preventDefault();
    props.parent_method("contact", props.sf_org_id)
  };
  const buttonPad = {
    marginBottom: "20px"
  };
  return (
    <div>
      <button type="submit" style={buttonPad} className="button-small button-primary" onClick={handleClick}>
        <span>
          Create New <b>Contact</b> at { props.sf_org_name }
        </span>
      </button>
    </div>
  );
};

export default SfContactButton;
