import AffiliatedWithCard from "./affiliatedWithCard";

const AffiliatedWithCards = (props) => {
  const returnCards = _.map(props.data, function(data, index){
    return <AffiliatedWithCard key={index} id={index} data={data} />
  });
  return (
    <div>{returnCards}</div>
  );
};

window.AffiliatedWithCards = AffiliatedWithCards;
export default AffiliatedWithCards;
