import ListCreateInProgress from "./ListCreateInProgress";
import ListCreateSuccess from "./ListCreateSuccess";

import {
  LIST_CREATE_REQUEST_STARTED,
  LIST_CREATE_REQUEST_SUCCESS,
  LIST_CREATE_REQUEST_FAILURE,
} from "../../../../constants/lists/list_constants";

const ListCreateStatus = ({ status, listName }) => {
  return (
    <div style={{}}>
      {status === LIST_CREATE_REQUEST_STARTED && (
        <ListCreateInProgress listName={listName} />
      )}
      {status === LIST_CREATE_REQUEST_SUCCESS && (
        <ListCreateSuccess listName={listName} />
      )}
      {status === LIST_CREATE_REQUEST_FAILURE && (
        <div>Failed to create list</div>
      )}
    </div>
  );
};

export default ListCreateStatus;
