import NameThisListCard from "../nameThisListCard";
import DownloadPrimoCampaignButton from "./downloadPrimoCampaignButton";

const DownloadPrimoPlModalBody = function (props) {
  const close_modal = function () {
    close_modal_control();
    return false;
  };

  let classNames = ["button-small", "button-primary"];
  if (props.checked !== true) {
    classNames.push("button-disable");
  }
  const campaign_types = { accounts: 'Accounts', contacts: 'Contacts Only', leads: 'Leads Only', contacts_and_leads: 'Contacts and Leads' };
  const campaign_buttons = Object.keys(campaign_types).map(function (entry, index) {
    return <DownloadPrimoCampaignButton {...props} key={index} type={entry} name={campaign_types[entry]} update_choice={props.update_choice} />;
  });

  /**
   * https://www.pivotaltracker.com/story/show/186622915
   * The keyword "ref" has a special meaning in React,
   * it should not be used.
   */
  const list = { ...props.list }
  list.list_ref = list.ref
  list.ref = null

  return (
    <div id="download_primo_pl_modal_body" className="modal_body">
      <NameThisListCard {...list} />
      <div className="download_primo_pl_instructions">
        <p>{I18n.t("brand.name")} can help you build faster and more targeted marketing campaigns by automating the retrieval of leads, contacts or accounts from Salesforce and combining them with company data from your PivotLists.</p><p>You can also use this feature to push PivotList information across Salesforce so your custom fields stay up to date. The campaign Excel file will display the {I18n.t("brand.name")} Organization ID and data columns from this PivotList alongside information and identifiers from Salesforce. </p>
        <p>Choose which Salesforce identifiers you wish to retrieve for this campaign:</p>
        <ul>
          <li>Selecting 'Accounts' will return matching SF Account IDs and the Account Name</li>
          <li>Selecting 'Contacts' will return the first name, last name, title, email and date of last activity for Contacts whose company matches a company on your PivotList</li>
          <li>Selecting 'Leads' will return the first name, last name, title, email and date of last activity for existing leads whose company matches a company on your PivotList</li>
        </ul>
        <p>You will receive a link to a secure download when the file is ready. Note that assembling the file may take up to 1 hour depending on the length of your list.</p>
      </div>
      <div className="centered_buttons buttonGroup-small">
        {campaign_buttons}
      </div>
      <div className="right_justified_modal_buttons buttonGroup-small">
        <button type="reset" className="button-small button-secondary" onClick={close_modal}>Cancel</button>
        <button type="submit" className={classNames.join(" ")} id="download_primo_pl_submit_button" onClick={props.submit_form}>Send Link</button>
      </div>
    </div>
  );
};

export default DownloadPrimoPlModalBody;
