import Button from "@javascript/reactComponents/lds/Button";
import ButtonGroup from "@javascript/reactComponents/lds/ButtonGroup";
import {
  ModalBody,
  ModalFooter,
} from "@javascript/reactComponents/lds/modal/Modal";
import NavItem from "@javascript/reactComponents/lds/NavItem";
import Heading from "@javascript/reactComponents/lds/typography/Heading";

const ListModalSuccess = (props) => {
  const goToList = () =>
    location.assign(`/lists/${props.list_id}?list_view=first`);

  return (
    <>
      <ModalBody>
        <div className="list-modal-success-content">
          <span className="icon-special-success" />

          <Heading size="h2">List generated successfully!</Heading>

          <p>
            You can also access all your lists on &nbsp;
            <NavItem
              id="lists-modal-to-lists-page-link"
              href="/lists"
              className="lists_modal_nav_link hovered"
              icon="list"
              variant="link"
            >
              Lists
            </NavItem>
            &nbsp; page.
          </p>
        </div>
      </ModalBody>

      <ModalFooter align="flex-end">
        <ButtonGroup size="medium">
          <Button
            id="dismiss-flag-event-success-modal"
            size="medium"
            variant="secondary"
            onClick={LegacyModalService.closeModal}
          >
            Close
          </Button>
          <Button
            id="go-to-flagged-events-page"
            size="medium"
            variant="primary"
            onClick={goToList}
          >
            Go to List
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </>
  );
};

export default ListModalSuccess;
